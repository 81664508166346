import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { getPolicyData } from "../../redux/reducers/policy/policy.action";
import { useSelector } from "react-redux";
import { useAction } from "../../helperFunctions/useAction";

const RenewalsBanner = () => {
  const [showRenewals, setShowRenewals] = useState(false);
  const [renewals, setRenewals] = useState({});
  const handleGetPolicy = useAction(getPolicyData);

  useEffect(() => {
    handleGetPolicy();
  }, []);

  const policyData = useSelector(
    (globalState) => globalState.policyData.policyData
  );

  useEffect(() => {
    if (Array.isArray(policyData)) {
      const newRenewals = {};
      const today = new Date();

      policyData.forEach((item) => {
        const {
          insuranceCompany,
          insuranceType,
          policyNo,
          customerName,
          expiryDate,
        } = item;
        const expiryDateObj = new Date(expiryDate);

        // Compare if today is within the 2 days range to expiry date
        const twoDaysBeforeExpiry = new Date(expiryDateObj);
        twoDaysBeforeExpiry.setDate(expiryDateObj.getDate() - 2);

        if (today >= twoDaysBeforeExpiry && today <= expiryDateObj) {
          updateRenewals(
            newRenewals,
            insuranceCompany,
            insuranceType,
            policyNo,
            customerName,
            expiryDate
          );
        }
      });

      setRenewals(newRenewals);
      setShowRenewals(Object.keys(newRenewals).length > 0);
    } else {
      console.warn("Invalid data provided:", policyData);
    }
  }, [policyData]);

  const updateRenewals = (
    renewals,
    insuranceCompany,
    insuranceType,
    policyNo,
    customerName,
    expiryDate
  ) => {
    if (!renewals[insuranceCompany]) {
      renewals[insuranceCompany] = [];
    }
    renewals[insuranceCompany].push({
      insuranceType,
      policyNo,
      customerName,
      expiryDate,
    });
  };

  if (showRenewals) {
    return (
      <>
        <div className="bg-white">
          <Marquee pauseOnHover={true}>
            <span className="text-primary fs-5 fw-bolder mx-2">
              Policy Renewals :
            </span>
            {Object.keys(renewals).map((company) =>
              renewals[company].map((policy, index) => (
                <div
                  key={`${company}-${index}`}
                  className="text-danger fw-bold mx-3"
                >
                  Policy No: {policy.policyNo}, Customer Name:{" "}
                  {policy.customerName}, Expiry Date: {policy.expiryDate},
                  Insurance Company: {company}.
                </div>
              ))
            )}
          </Marquee>
        </div>
      </>
    );
  }

  return null;
};

export default RenewalsBanner;
