import React from "react";

const UserProfile = ({ user }) => {
  return (
    <div className="profile_head shadow-lg bg-white rounded-pill px-3">
      <img src="/images/avatar/1.png" alt="user profile" />
      {user && <span>{user.username}</span>}
    </div>
  );
};

export default UserProfile;
