import {
  ADD_SERVICE_PROVIDER,
  DELETE_SERVICE_PROVIDER,
  GET_SERVICE_PROVIDER,
  UPDATE_SERVICE_PROVIDER,
} from "./sp.type";

const initialState = {
  serviceProviders: [],
};

const spReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_PROVIDER:
      return {
        ...state,
        serviceProviders: action.payload,
      };
    case ADD_SERVICE_PROVIDER:
      return {
        ...state,
      };
    case DELETE_SERVICE_PROVIDER:
      return {
        ...state,
      };
    case UPDATE_SERVICE_PROVIDER:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default spReducer;
