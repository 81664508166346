import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

export const getAgents = () => async (dispatch) => {
  try {
    const agents = await axios({
      method: "GET",
      url: `${BASE_URL}/agents`,
    });

    return dispatch({
      type: "GET_AGENTS",
      payload: agents.data.agentData,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const addNewAgent = (formData) => async (dispatch) => {
  try {
    const newAgent = await axios({
      method: "POST",
      url: `${BASE_URL}/agents/new`,
      data: { agentData: formData },
    });
    return dispatch({
      type: "ADD_AGENTS",
      payload: newAgent.data,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const deleteAgent = (_id) => async (dispatch) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${BASE_URL}/agents/delete/${_id}`,
    });

    return dispatch({
      type: "DELETE_AGENTS",
      payload: response.data.message,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const updateAgent = (updatedData) => async (dispatch) => {
  try {
    const id = updatedData._id;
    const updateAgent = await axios({
      method: "PUT",
      url: `${BASE_URL}/agents/update/${id}`,
      data: { agentData: updatedData },
    });
    return dispatch({
      type: "UPDATE_AGENTS",
      payload: updateAgent.data,
    });
  } catch (error) {
    return dispatch({
      type: "ERROR",
      payload: error,
    });
  }
};
