import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useAction = (actionFn) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useCallback(
    async (formData) => {
      const result = await dispatch(actionFn(formData));
      if (result.type.includes("ADD")) {
        toast.success("Added successfully");
      } else if (result.type.includes("UPDATE")) {
        toast.success("Updated successfully");
      } else if (result.type.includes("DELETE")) {
        toast.success("Deleted successfully");
      } else if (result.type.includes("SIGN_IN")) {
        toast.success("Signed in successfully");
      } else if (result.type.includes("SIGN_OUT")) {
        toast.success("Sign Out successfully");
      } else if (result.type.includes("SIGN_UP")) {
        toast.success("Sign up successfully");
        navigate("/dashboard");
      } else if (result.type.includes("RESET")) {
        toast.success("Password Changed");
      } else if (result.type.includes("ERROR")) {
        toast.error(result.payload.response.data.error);
        toast.error(result.payload.response.data);
      }
      return result;
    },
    [dispatch, actionFn, navigate]
  );
};
