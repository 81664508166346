import {
  ADD_POLICY_DATA,
  DELETE_POLICY_DATA,
  GET_POLICY_DATA,
  UPDATE_POLICY_DATA,
} from "./policy.type";

const initialState = {
  policyData: [],
};

const newPolicy = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POLICY_DATA:
      return {
        ...state,
      };
    case GET_POLICY_DATA:
      return {
        ...state,
        policyData: action.payload,
      };
    case DELETE_POLICY_DATA:
      return {
        ...state,
      };
    case UPDATE_POLICY_DATA:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default newPolicy;
