import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// components Calling
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import PolicyForm from "./components/policy/PolicyForm";
import PolicyList from "./components/policy/PolicyList";
import RenewalsList from "./components/renewals/RenewalsList";
import MasterListing from "./components/MasterComponents/MasterListing";
import MasterLedger from "./components/MasterComponents/MasterLedger";
import SidebarPro from "./components/SidebarPro";

//CSS files
import "./App.css";
import "../src/css/custom_style.css";
import "../src/css/userProfile.css";

import "primereact/resources/themes/lara-light-cyan/theme.css";

// bootstrap js
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";

// calling configurations
import {
  GST,
  agentFormInputFields,
  modalButtonsConfig,
  agentColumn,
  spFormInputFields,
  spColumn,
  customerFormInputFields,
  customerColumn,
  agentCollectionColumn,
  agentLedgerColumns,
  companyLedgerColumns,
  companyCollectionColumn,
  spCollectionColumn,
  spLedgerColumns,
  customerLedgerColumns,
  customerCollectionColumn,
} from "./configurations/config";
import selectFieldData from "./configurations/selectFieldData";

// Redux functions called
import {
  getAgents,
  deleteAgent,
  addNewAgent,
  updateAgent,
} from "./redux/reducers/agents/agents.action";
import {
  addNewSP,
  deleteSp,
  getSP,
  updateSp,
} from "./redux/reducers/serviceProvider/sp.action";
import {
  addNewCustomer,
  deleteCustomer,
  getCustomers,
  updateCustomer,
} from "./redux/reducers/customer/customer.action";
import {
  addCollection,
  deleteCollection,
  getCollection,
  updateCollection,
} from "./redux/reducers/collection/collection.action";
import {
  deletePolicy,
  getPolicyData,
  updatePolicy,
  addPolicy,
} from "./redux/reducers/policy/policy.action";

// toastr
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAction } from "./helperFunctions/useAction.js";
import ResetPassword from "./components/ResetPassword.jsx";
import IndividualLedger from "./components/IndividualLedger.jsx";

function App() {
  const isAuthenticated = !!localStorage.getItem("bimabook");

  const location = useLocation();
  const navigate = useNavigate();
  const handleGetPolicy = useAction(getPolicyData);
  const handleGetCollection = useAction(getCollection);
  const handleGetAgents = useAction(getAgents);
  const handleGetSp = useAction(getSP);
  const handleGetCustomers = useAction(getCustomers);
  const handleAddPolicy = useAction(addPolicy);
  const handleupdatePolicy = useAction(updatePolicy);
  useEffect(() => {
    if (isAuthenticated) {
      handleGetAgents();
      handleGetCollection();
      handleGetSp();
      handleGetCustomers();
      handleGetPolicy();
    }
  }, [
    isAuthenticated,
    handleGetAgents,
    handleGetCollection,
    handleGetSp,
    handleGetCustomers,
    handleGetPolicy,
  ]);

  const policyList = useSelector(
    (globalState) => globalState.policyData.policyData
  );
  const agentsList = useSelector(
    (globalState) => globalState.agentsReducer.agents
  );
  const spList = useSelector(
    (globalState) => globalState.spReducer.serviceProviders
  );
  const customersList = useSelector(
    (globalState) => globalState.customerReducer.customers
  );
  const collectionList = useSelector(
    (globalState) => globalState.collectionReducer.collectionList
  );

  const handleFormSubmit = async (formdata) => {
    for (var key of formdata.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    if (formdata.has("_id")) {
      await handleupdatePolicy(formdata);
      await handleGetPolicy();
      navigate("/dashboard/policyList");
    } else {
      await handleAddPolicy(formdata);
      await handleGetPolicy();
    }
  };

  return (
    <>
      <ToastContainer position="bottom-right" pauseOnFocusLoss={false} />

      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route path="/login" element={<SignIn />} />
        <Route path="/sidebar" element={<SidebarPro />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route
              path="newPolicy"
              element={<PolicyForm gst={GST} onSubmit={handleFormSubmit} />}
            />
            <Route
              path="policyUpdate/:id"
              element={<PolicyForm onSubmit={handleFormSubmit} />}
            />
            <Route
              path="policyList"
              element={
                <PolicyList
                  getFunction={getPolicyData}
                  deleteFunction={deletePolicy}
                  policyData={policyList}
                />
              }
            />
            <Route
              path="ledgers/:_id"
              element={
                <IndividualLedger
                  masterDataType={agentsList}
                  basedOn={"agentName"}
                />
              }
            />
            <Route
              path="customers"
              element={
                <MasterListing
                  key={location.pathname}
                  dataTableArray={customersList}
                  modalButtonsConfig={modalButtonsConfig}
                  inputFields={customerFormInputFields}
                  getFunction={getCustomers}
                  addFunction={addNewCustomer}
                  deleteFunction={deleteCustomer}
                  updateFunction={updateCustomer}
                  columnsToShow={customerColumn}
                  basedOn={"customerId"}
                  title={"Customers"}
                />
              }
            />
            <Route
              path="serviceProviders"
              element={
                <MasterListing
                  key={location.pathname}
                  dataTableArray={spList}
                  modalButtonsConfig={modalButtonsConfig}
                  inputFields={spFormInputFields}
                  getFunction={getSP}
                  addFunction={addNewSP}
                  deleteFunction={deleteSp}
                  columnsToShow={spColumn}
                  updateFunction={updateSp}
                  basedOn={"serviceProviderId"}
                  title={"Service Providers"}
                />
              }
            />
            <Route
              path="agents"
              element={
                <MasterListing
                  key={location.pathname}
                  dataTableArray={agentsList}
                  modalButtonsConfig={modalButtonsConfig}
                  inputFields={agentFormInputFields}
                  getFunction={getAgents}
                  addFunction={addNewAgent}
                  deleteFunction={deleteAgent}
                  columnsToShow={agentColumn}
                  updateFunction={updateAgent}
                  basedOn={"agentId"}
                  title={"Agents"}
                />
              }
            />
            {/** 
            <Route
              path="companyLedger"
              element={
                <MasterLedger
                  key={location.pathname}
                  collectionArray={collectionList}
                  policyArray={policyList}
                  collectionColumns={companyCollectionColumn}
                  ledgerColumns={companyLedgerColumns}
                  basedOn={"insuranceCompany"}
                  getFunction={getCollection}
                  deleteFunction={deleteCollection}
                  addFunction={addCollection}
                  updateFunction={updateCollection}
                  title={"Company Ledger"}
                  masterData={selectFieldData[1].insurance_company}
                />
              }
            />*/}
            <Route
              path="agentLedger"
              element={
                <MasterLedger
                  key={location.pathname}
                  collectionArray={collectionList}
                  policyArray={policyList}
                  collectionColumns={agentCollectionColumn}
                  ledgerColumns={agentLedgerColumns}
                  basedOn={"agentName"}
                  getFunction={getCollection}
                  deleteFunction={deleteCollection}
                  addFunction={addCollection}
                  updateFunction={updateCollection}
                  title={"Agent Ledger"}
                  masterData={agentsList}
                />
              }
            />
            <Route
              path="customerLedger"
              element={
                <MasterLedger
                  key={location.pathname}
                  collectionArray={collectionList}
                  policyArray={policyList}
                  collectionColumns={customerCollectionColumn}
                  ledgerColumns={customerLedgerColumns}
                  basedOn={"customerName"}
                  getFunction={getCollection}
                  deleteFunction={deleteCollection}
                  addFunction={addCollection}
                  updateFunction={updateCollection}
                  title={"Customer Ledger"}
                  masterData={customersList}
                />
              }
            />
            <Route
              path="spLedger"
              element={
                <MasterLedger
                  key={location.pathname}
                  collectionArray={collectionList}
                  policyArray={policyList}
                  collectionColumns={spCollectionColumn}
                  ledgerColumns={spLedgerColumns}
                  basedOn={"serviceProviderName"}
                  getFunction={getCollection}
                  deleteFunction={deleteCollection}
                  addFunction={addCollection}
                  updateFunction={updateCollection}
                  title={"Service Provider Ledger"}
                  masterData={spList}
                />
              }
            />
            <Route path="renewals" element={<RenewalsList />} />
            <Route path="resetPwd" element={<ResetPassword />} />
          </Route>
        </Route>

        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </>
  );
}

export default App;
