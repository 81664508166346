import React, { forwardRef, useState, useImperativeHandle } from "react";
import Modal from "../Modal";
import CollectionForm from "../CollectionForm";
import { useAction } from "../../helperFunctions/useAction";

const AddMaster = forwardRef(
  (
    {
      buttonsConfig,
      inputFields,
      getFunction,
      addFunction,
      updateFunction,
      basedOn,
    },
    ref
  ) => {
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [toUpdate, setToUpdate] = useState({});
    const handleAddAction = useAction(addFunction);
    const handleUpdateAction = useAction(updateFunction);
    const handleGetAction = useAction(getFunction);
    const handleButtonClick = () => {
      if (basedOn === "agentId") {
        setModalTitle("Add New Agent");
      } else if (basedOn === "customerId") {
        setModalTitle("Add New Customer");
      } else if (basedOn === "serviceProviderId") {
        setModalTitle("Add New Service Provider");
      }
      setToUpdate({});
      setShowModal(true);
    };

    useImperativeHandle(ref, () => ({
      handleEdit(rowData) {
        if (basedOn === "agentId") {
          setModalTitle("Update Agent Details");
        } else if (basedOn === "customerId") {
          setModalTitle("Update Customer Details");
        } else if (basedOn === "serviceProviderId") {
          setModalTitle("Update Service Provider Details");
        }
        setToUpdate(rowData);
        setShowModal(true);
      },
    }));
    const handleCloseModal = () => {
      setShowModal(false);
      handleGetAction();
    };

    const handleSaveFormData = (formData) => {
      if ("_id" in formData) {
        handleUpdateAction(formData);
        handleGetAction();
      } else {
        handleAddAction(formData);
        handleGetAction();
      }
      handleCloseModal();
    };
    return (
      <>
        {buttonsConfig && (
          <div>
            {buttonsConfig.map((button, index) => {
              const IconComponent = button.icon;
              const buttonClass =
                button.className || "btn btn-sm btn-outline-primary  m-2"; // Default Bootstrap class if none provided
              return IconComponent ? (
                <IconComponent
                  key={index}
                  className={`btn-action ${buttonClass}`}
                  title={button.label}
                  style={{ cursor: "pointer", margin: "0 5px" }}
                  onClick={() => handleButtonClick()} // Using the local handleClick
                />
              ) : (
                <button
                  key={index}
                  className={`btn-action ${buttonClass}`}
                  data-index={index}
                  onClick={() => handleButtonClick()} // Using the local handleClick
                >
                  {button.label}
                </button>
              );
            })}
          </div>
        )}
        <Modal
          show={showModal}
          title={modalTitle}
          onClose={handleCloseModal}
          inputFields={inputFields}
          handleSaveFormData={handleSaveFormData}
          updateData={toUpdate}
        >
          <CollectionForm
            onClose={handleCloseModal}
            onFormSubmit={handleSaveFormData}
            updateData={toUpdate}
          />
        </Modal>
      </>
    );
  }
);

export default AddMaster;
