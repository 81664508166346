import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { LiaClipboardListSolid } from "react-icons/lia";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FaRegFileAlt } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { MdOutlinePolicy, MdAutorenew, MdLockReset } from "react-icons/md";
import { LuFiles } from "react-icons/lu";
import { RiUser2Line } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";

const sidebarItems = {
  "/dashboard/newPolicy": { open: "Policy", active: "newPolicy" },
  "/dashboard/policyList": { open: "Policy", active: "policyList" },
  "/dashboard/customers": { open: "Master Records", active: "customers" },
  "/dashboard/serviceProviders": {
    open: "Master Records",
    active: "serviceProviders",
  },
  "/dashboard/agents": { open: "Master Records", active: "agents" },
  "/dashboard/companyLedger": { open: "Ledgers", active: "companyLedger" },
  "/dashboard/spLedger": { open: "Ledgers", active: "spLedger" },
  "/dashboard/agentLedger": { open: "Ledgers", active: "agentLedger" },
  "/dashboard/customerLedger": { open: "Ledgers", active: "customerLedger" },
  "/dashboard/renewals": { open: "", active: "renewals" },
  "/dashboard/resetPwd": { open: "", active: "resetPwd" },
};

const SidebarPro = () => {
  const [open, setOpen] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (sidebarItems[pathname]) {
      const { open, active } = sidebarItems[pathname];
      setOpen(open);
      setActiveItem(active);
    }
  }, [location.pathname]);

  const handleOpenSubMenu = (key) => {
    setOpen((prevOpen) => (prevOpen === key ? "" : key));
  };

  const handleActiveItem = (item) => {
    setActiveItem(item);
  };

  return (
    <>
      <Sidebar
        collapsed={collapsed}
        rootStyles={{
          background:
            "linear-gradient(180deg, rgba(246,218,255,1) 0%, rgba(140,44,255,1) 100%);",
        }}
      >
        <Menu transitionDuration={500}>
          <MenuItem
            icon={<RxHamburgerMenu className="fs-3 text-danger " />}
            onClick={() => setCollapsed(!collapsed)}
          >
            MENU
          </MenuItem>

          <SubMenu
            onClick={() => handleOpenSubMenu("Policy")}
            open={open === "Policy"}
            label="Policy"
            title="Policy"
            icon={<MdOutlinePolicy className="fs-4 text-danger" />}
            rootStyles={{ backgroundColor: open === "Policy" ? "#deacfc" : "" }}
          >
            <MenuItem
              component={<Link to="/dashboard/newPolicy" />}
              icon={<LiaClipboardListSolid className="fs-5 text-primary" />}
              active={activeItem === "newPolicy"}
              onClick={() => handleActiveItem("newPolicy")}
              rootStyles={{
                backgroundColor: activeItem === "newPolicy" ? "#edcbf7" : "",
              }}
            >
              New Policy
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/policyList" />}
              icon={<AiOutlineUnorderedList className="fs-5 text-primary" />}
              active={activeItem === "policyList"}
              onClick={() => handleActiveItem("policyList")}
              rootStyles={{
                backgroundColor: activeItem === "policyList" ? "#edcbf7" : "",
              }}
            >
              Policy List
            </MenuItem>
          </SubMenu>
          <SubMenu
            onClick={() => handleOpenSubMenu("Master Records")}
            open={open === "Master Records"}
            label="Master Records"
            title="Master Records"
            icon={<LuFiles className="fs-4 text-danger" />}
            rootStyles={{
              backgroundColor: open === "Master Records" ? "#deacfc" : "",
            }}
          >
            <MenuItem
              component={<Link to="/dashboard/customers" />}
              icon={<AiOutlineUsergroupAdd className="fs-5 text-primary" />}
              active={activeItem === "customers"}
              onClick={() => handleActiveItem("customers")}
              rootStyles={{
                backgroundColor: activeItem === "customers" ? "#edcbf7" : "",
              }}
            >
              Customers List
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/serviceProviders" />}
              icon={<GrServices className="fs-5 text-primary" />}
              active={activeItem === "serviceProviders"}
              onClick={() => handleActiveItem("serviceProviders")}
              rootStyles={{
                backgroundColor:
                  activeItem === "serviceProviders" ? "#edcbf7" : "",
              }}
            >
              Service Providers List
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/agents" />}
              icon={<RiUser2Line className="fs-5 text-primary" />}
              active={activeItem === "agents"}
              onClick={() => handleActiveItem("agents")}
              rootStyles={{
                backgroundColor: activeItem === "agents" ? "#edcbf7" : "",
              }}
            >
              Agents List
            </MenuItem>
          </SubMenu>
          <SubMenu
            onClick={() => handleOpenSubMenu("Ledgers")}
            open={open === "Ledgers"}
            label="Ledgers"
            title="Ledgers"
            icon={<FaRegFileAlt className="fs-4 text-danger" />}
            rootStyles={{
              backgroundColor: open === "Ledgers" ? "#deacfc" : "",
            }}
          >
            {/** 
            <MenuItem
              component={<Link to="/dashboard/companyLedger" />}
              icon={<AiOutlineUnorderedList className="fs-5 text-primary" />}
              active={activeItem === "companyLedger"}
              onClick={() => handleActiveItem("companyLedger")}
              rootStyles={{
                backgroundColor:
                  activeItem === "companyLedger" ? "#edcbf7" : "",
              }}
            >
              Company Ledger
            </MenuItem>*/}
            <MenuItem
              component={<Link to="/dashboard/spLedger" />}
              icon={<AiOutlineUnorderedList className="fs-5 text-primary" />}
              active={activeItem === "spLedger"}
              onClick={() => handleActiveItem("spLedger")}
              rootStyles={{
                backgroundColor: activeItem === "spLedger" ? "#edcbf7" : "",
              }}
            >
              Service Providers Ledger
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/agentLedger" />}
              icon={<AiOutlineUnorderedList className="fs-5 text-primary" />}
              active={activeItem === "agentLedger"}
              onClick={() => handleActiveItem("agentLedger")}
              rootStyles={{
                backgroundColor: activeItem === "agentLedger" ? "#edcbf7" : "",
              }}
            >
              Agents Ledger
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/customerLedger" />}
              icon={<AiOutlineUnorderedList className="fs-5 text-primary" />}
              active={activeItem === "customerLedger"}
              onClick={() => handleActiveItem("customerLedger")}
              rootStyles={{
                backgroundColor:
                  activeItem === "customerLedger" ? "#edcbf7" : "",
              }}
            >
              Customer Ledger
            </MenuItem>
          </SubMenu>
          <MenuItem
            component={<Link to="/dashboard/renewals" />}
            icon={<MdAutorenew className="fs-3 text-danger" />}
            active={activeItem === "renewals"}
            title="Renewals"
            onClick={() => handleActiveItem("renewals")}
            rootStyles={{
              backgroundColor: activeItem === "renewals" ? "#edcbf7" : "",
            }}
          >
            Renewals
          </MenuItem>
          <MenuItem
            component={<Link to="/dashboard/resetPwd" />}
            icon={<MdLockReset className="fs-3 text-danger" />}
            active={activeItem === "resetPwd"}
            title="Reset Password"
            onClick={() => handleActiveItem("resetPwd")}
            rootStyles={{
              backgroundColor: activeItem === "resetPwd" ? "#edcbf7" : "",
            }}
          >
            Reset Password
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
};

export default SidebarPro;
