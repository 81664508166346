import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

export const getCustomers = () => async (dispatch) => {
  try {
    const customers = await axios({
      method: "GET",
      url: `${BASE_URL}/customers`,
    });

    return dispatch({
      type: "GET_CUSTOMER",
      payload: customers.data.customerData,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const addNewCustomer = (formData) => async (dispatch) => {
  try {
    const newCustomer = await axios({
      method: "POST",
      url: `${BASE_URL}/customers/new`,
      data: { customerData: formData },
    });
    return dispatch({
      type: "ADD_CUSTOMER",
      payload: newCustomer.data,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const deleteCustomer = (_id) => async (dispatch) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${BASE_URL}/customers/delete/${_id}`,
    });

    return dispatch({
      type: "DELETE_CUSTOMER",
      payload: response.data.message,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const updateCustomer = (updatedData) => async (dispatch) => {
  try {
    const id = updatedData._id;
    const updateCustomer = await axios({
      method: "PUT",
      url: `${BASE_URL}/customers/update/${id}`,
      data: { customerData: updatedData },
    });
    return dispatch({
      type: "UPDATE_CUSTOMER_DATA",
      payload: updateCustomer.data,
    });
  } catch (error) {
    return dispatch({
      type: "ERROR",
      payload: error,
    });
  }
};
