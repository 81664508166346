import React, { useEffect, useState } from "react";
import { getMySelf } from "../redux/reducers/user/user.action";
import { resetPwd } from "../redux/reducers/auth/auth.action";
import { useAction } from "../helperFunctions/useAction";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [userData, setUserData] = useState({
    _id: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleGetSelf = useAction(getMySelf);
  const handleResetPwd = useAction(resetPwd);

  useEffect(() => {
    const response = handleGetSelf();
    response.then(function (result) {
      setUserData((prevData) => ({
        ...prevData,
        _id: result.payload._id,
        email: result.payload.email,
      }));
    });
  }, [handleGetSelf]);

  const [errors, setErrors] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUserData((prev) => ({ ...prev, [id]: value }));

    // Validate on change
    validateField(id, value);
  };

  const validateField = (id, value) => {
    let isValid = true;

    if (id === "newPassword") {
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      isValid = passwordPattern.test(value);
    } else if (id === "confirmPassword") {
      isValid = value === userData.newPassword;
    } else {
      isValid = value.trim() !== "";
    }

    setErrors((prev) => ({ ...prev, [id]: !isValid }));
  };

  const handleSubmit = async () => {
    const { newPassword, confirmPassword } = userData;
    const newErrors = {
      newPassword:
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          newPassword
        ),
      confirmPassword: confirmPassword !== newPassword,
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      toast.error("Please fill out all fields correctly.");
      return;
    }

    try {
      await handleResetPwd(userData);
      setUserData({
        _id: "",
        email: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      setErrors({
        password: false,
        newPassword: false,
        confirmPassword: false,
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        alert("An error occurred while Reseting Password.");
      }
    }
  };

  return (
    <div className="">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6 signup_card">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="col-xl-6 col-md-6 sign text-center">
                      <div>
                        <div className="text-start m-3">
                          <div className="logo">
                            <span className="text-white fw-bold fs-1">
                              BimaBook
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 className="text-center text-white mb-4">
                      Reset Password
                    </h4>
                    <form>
                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="password" className="mb-1">
                          <strong>Old Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className={`form-control py-2 ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          placeholder="Old Password"
                          value={userData.password}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="newPassword" className="mb-1">
                          <strong>New Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="newPassword"
                          className={`form-control py-2 ${
                            errors.newPassword ? "is-invalid" : ""
                          }`}
                          placeholder="New Password"
                          value={userData.newPassword}
                          onChange={handleChange}
                          required
                          pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
                        />
                        {errors.newPassword && (
                          <div className="invalid-feedback text-white">
                            <span>Password Conditions:</span>
                            <ul>
                              <li>
                                Password must be at least 8 characters long.
                              </li>
                              <li>
                                It should contain at least one uppercase(A-Z)
                                and one lowercase(a-z) letter.
                              </li>
                              <li>It should contain at least one digit.</li>
                              <li>
                                It should contain at least one special
                                character.
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="confirmPassword" className="mb-1">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="confirmPassword"
                          className={`form-control py-2 ${
                            errors.confirmPassword ? "is-invalid" : ""
                          }`}
                          placeholder="Confirm Password"
                          value={userData.confirmPassword}
                          onChange={handleChange}
                          required
                        />
                        {errors.confirmPassword && (
                          <div className="invalid-feedback text-white">
                            Passwords do not match.
                          </div>
                        )}
                      </div>

                      <div className="form-check text-white mx-5">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="showPassword"
                          checked={showPassword}
                          onChange={() => setShowPassword(!showPassword)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="showPassword"
                        >
                          Show Password
                        </label>
                      </div>
                      <div className="text-white my-2 mx-5"></div>
                      <div className="text-center my-4 mx-5">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-primary btn-block form-control py-3"
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
