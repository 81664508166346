import React, { useState, useEffect } from "react";
import Select from "react-select";
import selectFieldData from "../../configurations/selectFieldData.json";
import { IoIosAddCircleOutline } from "react-icons/io";

const BasicDetails = ({
  formData,
  handleChange,
  handleBlur,
  isMotorInsurance,
  handleAddCustomer,
  handleAddSP,
  handleAddAgent,
  handleCustomerChange,
  customerList,
  spList,
  agentsList,
  isAgent,
  insuranceAgentField,
}) => {
  const [customers, setCustomers] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [agents, setAgents] = useState([]);
  const [isInsuredNameManuallyChanged, setIsInsuredNameManuallyChanged] =
    useState(false);

  useEffect(() => {
    if (customerList) {
      setCustomers(customerList.filter((item) => item.status === "Active"));
    }
  }, [customerList]); // Dependency on customerList only

  useEffect(() => {
    if (spList) {
      setServiceProviders(spList.filter((item) => item.status === "Active"));
    }
  }, [spList]); // Dependency on spList only

  useEffect(() => {
    if (agentsList) {
      setAgents(agentsList.filter((item) => item.status === "Active"));
    }
  }, [agentsList]); // Dependency on agentsList only

  useEffect(() => {
    if (formData.insuranceAgent === "Self" && !isInsuredNameManuallyChanged) {
      const selectedCustomer = customers.find(
        (customer) => customer._id === formData.customerId
      );
      if (selectedCustomer) {
        handleChange({
          name: "insuredName",
          value: selectedCustomer.customerName,
        });
      }
    }
  }, [
    formData.insuranceAgent,
    formData.customerId,
    customers,
    isInsuredNameManuallyChanged,
  ]);

  const handleInsuredNameChange = (e) => {
    setIsInsuredNameManuallyChanged(true);
    handleChange(e);
  };

  // Helper function to create options
  const createOptions = (data) =>
    data?.map((item) => ({
      value: item,
      label: item,
    })) || [];

  // Options for react-select fields

  const customerOptions = customers.map((customer) => ({
    value: customer._id,
    label: customer.customerName,
  }));

  const agentOptions = agents.map((agent) => ({
    value: agent._id,
    label: agent.agentName,
  }));

  const serviceProviderOptions = serviceProviders.map((sp) => ({
    value: sp._id,
    label: sp.serviceProviderName,
  }));

  const insuranceCompanyOptions = selectFieldData[1].insurance_company.map(
    (company) => ({
      value: company._id,
      label: company.insuranceCompany,
    })
  );

  const insuranceAgentOptions = createOptions(
    selectFieldData[7]?.insurance_agent
  );
  const insuranceTypeOptions = createOptions(
    selectFieldData[0]?.insurance_type
  );
  const vehicleTypeOptions = createOptions(selectFieldData[8]?.vehicle_type);
  const paymentTypeOptions = createOptions(selectFieldData[2]?.payment_type);

  // Determine if the field should be disabled
  const defaultPaymentType = isMotorInsurance ? "Yearly" : formData.paymentType;

  // Find the selected options for react-select fields
  const findSelectedOption = (options, value) => {
    return options.find((option) => option.value === value) || null;
  };

  return (
    <>
      <div className="row  g-3">
        <h5 className="text-primary">Basic Info:</h5>
        <div className="col-md-4">
          <label htmlFor="insuranceAgent" className="form-label">
            Insurance Agent<span className="text-danger">*</span>
          </label>
          <Select
            id="insuranceAgent"
            name="insuranceAgent"
            onChange={(selectedOption) =>
              handleChange({
                name: "insuranceAgent",
                value: selectedOption ? selectedOption.value : "",
              })
            }
            value={findSelectedOption(
              insuranceAgentOptions,
              formData.insuranceAgent
            )}
            options={insuranceAgentOptions}
            isDisabled={insuranceAgentField}
            isClearable
            placeholder="Choose..."
            required
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="policyNo" className="form-label">
            Policy Number<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control form-control"
            id="policyNo"
            name="policyNo"
            value={formData.policyNo}
            onChange={handleChange}
            required
            onBlur={handleBlur}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="insuranceCompanyId" className="form-label">
            Insurance Company<span className="text-danger">*</span>
          </label>
          <Select
            id="insuranceCompanyId"
            name="insuranceCompanyId"
            value={findSelectedOption(
              selectFieldData[1].insurance_company.map((company) => ({
                value: company._id,
                label: company.insuranceCompany,
              })),
              formData.insuranceCompanyId
            )}
            onChange={(selectedOption) =>
              handleCustomerChange({
                target: {
                  name: "insuranceCompanyId",
                  value: selectedOption ? selectedOption.value : "",
                  dataset: {
                    name: selectedOption ? selectedOption.label : "",
                  },
                },
              })
            }
            options={insuranceCompanyOptions}
            isClearable
            placeholder="Choose..."
            required
          />
        </div>

        {!isAgent && (
          <div className={"col-md-4"}>
            <label htmlFor="customerId" className="form-label">
              Customer<span className="text-danger">*</span>
            </label>
            <div className="d-flex align-items-center">
              <Select
                name="customerId"
                id="customerId"
                className="w-100"
                value={findSelectedOption(
                  customers.map((customer) => ({
                    value: customer._id,
                    label: customer.customerName,
                  })),
                  formData.customerId
                )}
                onChange={(selectedOption) =>
                  handleCustomerChange({
                    target: {
                      name: "customerId",
                      value: selectedOption ? selectedOption.value : "",
                      dataset: {
                        name: selectedOption ? selectedOption.label : "",
                      },
                    },
                  })
                }
                options={customerOptions}
                isClearable
                placeholder="Choose..."
                required
              />
              <IoIosAddCircleOutline
                className="ms-2 fs-3 text-primary rounded-circle"
                onClick={handleAddCustomer}
                role="button"
              />
            </div>
          </div>
        )}

        {isAgent && (
          <div className={"col-md-4"}>
            <label htmlFor="agentId" className="form-label">
              Agent<span className="text-danger">*</span>
            </label>
            <div className="d-flex align-items-center">
              <Select
                name="agentId"
                id="agentId"
                className="w-100"
                value={findSelectedOption(
                  agents.map((agent) => ({
                    value: agent._id,
                    label: agent.agentName,
                  })),
                  formData.agentId
                )}
                onChange={(selectedOption) =>
                  handleCustomerChange({
                    target: {
                      name: "agentId",
                      value: selectedOption ? selectedOption.value : "",
                      dataset: {
                        name: selectedOption ? selectedOption.label : "",
                      },
                    },
                  })
                }
                options={agentOptions}
                isClearable
                placeholder="Choose..."
                required
              />
              <IoIosAddCircleOutline
                className="ms-2 fs-3 text-primary rounded-circle"
                onClick={handleAddAgent}
                role="button"
              />
            </div>
          </div>
        )}

        <div className={"col-md-4"}>
          <label htmlFor="serviceProviderId" className="form-label">
            Service Provider<span className="text-danger">*</span>
          </label>
          <div className="d-flex align-items-center">
            <Select
              name="serviceProviderId"
              id="serviceProviderId"
              className="w-100"
              value={findSelectedOption(
                serviceProviders.map((sp) => ({
                  value: sp._id,
                  label: sp.serviceProviderName,
                })),
                formData.serviceProviderId
              )}
              onChange={(selectedOption) =>
                handleCustomerChange({
                  target: {
                    name: "serviceProviderId",
                    value: selectedOption ? selectedOption.value : "",
                    dataset: {
                      name: selectedOption ? selectedOption.label : "",
                    },
                  },
                })
              }
              options={serviceProviderOptions}
              isClearable
              placeholder="Choose..."
              required
            />
            <IoIosAddCircleOutline
              className="ms-2 fs-3 text-primary rounded-circle"
              onClick={handleAddSP}
              role="button"
            />
          </div>
        </div>

        <div className="col-md-4">
          <label htmlFor="insuredName" className="form-label">
            Insured Name<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="insuredName"
            name="insuredName"
            value={formData.insuredName}
            onChange={handleInsuredNameChange}
            required
          />
        </div>
      </div>

      <div className="row g-3 mt-1">
        <div className={isMotorInsurance ? "col-12" : "col-md-4"}>
          <label htmlFor="insuranceType" className="form-label">
            Insurance Type<span className="text-danger">*</span>
          </label>
          <Select
            id="insuranceType"
            name="insuranceType"
            onChange={(selectedOption) =>
              handleChange({
                name: "insuranceType",
                value: selectedOption ? selectedOption.value : "",
              })
            }
            value={findSelectedOption(
              insuranceTypeOptions,
              formData.insuranceType
            )}
            options={insuranceTypeOptions}
            isClearable
            placeholder="Choose..."
            required
          />
        </div>
        {isMotorInsurance && (
          <div className="col-md-4">
            <label htmlFor="registrationNo" className="form-label">
              Registration Number.<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="registrationNo"
              name="registrationNo"
              value={formData.registrationNo}
              onChange={handleChange}
              required
              onBlur={handleBlur}
              disabled={!isMotorInsurance}
            />
          </div>
        )}
        {isMotorInsurance && (
          <div className="col-md-4">
            <label htmlFor="vehicleType" className="form-label">
              Vehicle Type<span className="text-danger">*</span>
            </label>
            <Select
              id="vehicleType"
              name="vehicleType"
              onChange={(selectedOption) =>
                handleChange({
                  name: "vehicleType",
                  value: selectedOption ? selectedOption.value : "",
                })
              }
              value={findSelectedOption(
                vehicleTypeOptions,
                formData.vehicleType
              )}
              options={vehicleTypeOptions}
              isClearable
              placeholder="Choose..."
              required
            />
          </div>
        )}
        <div className="col-md-4">
          <label htmlFor="paymentType" className="form-label">
            Payment Type<span className="text-danger">*</span>
          </label>
          <Select
            id="paymentType"
            name="paymentType"
            onChange={(selectedOption) =>
              handleChange({
                name: "paymentType",
                value: selectedOption ? selectedOption.value : "",
              })
            }
            value={findSelectedOption(paymentTypeOptions, defaultPaymentType)}
            options={paymentTypeOptions}
            isClearable={!isMotorInsurance} // Disable clear option if motor insurance
            placeholder="Choose..."
            required
            readOnly={isMotorInsurance} // Disable the field if motor insurance
          />
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
