import React, { useEffect, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const FileDropzone = ({
  onFilesAdded,
  onFileRemoved,
  label,
  fileType,
  reset,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // If reset is called, set selectedFile to null
    if (reset) {
      setSelectedFile(null);
    }
  }, [reset]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        if (!selectedFile) {
          setSelectedFile(acceptedFiles[0]);
          onFilesAdded(acceptedFiles, fileType);
        } else {
          toast.error("You can only upload one file.");
        }
      }

      if (rejectedFiles.length > 0) {
        toast.error(
          "Some files were rejected. Only PDF, JPG, and PNG files are allowed."
        );
      }
    },
    [selectedFile, onFilesAdded, fileType]
  );

  const resetFiles = () => {
    setSelectedFile(null);
    onFileRemoved(selectedFile, fileType); // Notify parent component about the removal
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles: 1,
  });

  return (
    <div className="row g-3">
      <div className="col-12">
        <label htmlFor="files" className="form-label">
          {label}
          {label === "Current Policy" && <span className="text-danger">*</span>}
        </label>
        <div className="file-dropzone">
          <div
            {...getRootProps({
              className:
                "dropzone rounded d-flex flex-column justify-content-center align-items-center",
            })}
          >
            <input {...getInputProps()} />
            <p className="text-muted">
              Drag 'n' drop files here, or click to select files
            </p>
          </div>
          {selectedFile && (
            <div className="row g-3 mt-1  text-start">
              <span className="col-8">{selectedFile.name}</span>
              <button
                type="button"
                className="btn btn-danger btn-sm col-md-4"
                onClick={() => resetFiles()}
              >
                Remove
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileDropzone;
