import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { useAction } from "../../helperFunctions/useAction";
import { toast } from "react-toastify";
import moment from "moment";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

// Components Call
import Modal from "../Modal"; // Ensure the import path is correct
import FileDropzone from "../FileDropzone";
import BasicDetails from "./BasicDetails";
import PolicyDates from "./PolicyDates";
import PolicyPremiums from "./PolicyPremiums";

// Jquery
import "select2/dist/css/select2.css";
import "select2";
import $ from "jquery";

// Configuration call
import {
  customerFormInputFields,
  agentFormInputFields,
  spFormInputFields,
  formDataEntriesBothFalse,
  formDataEntriesAgentFalse,
  formDataEntriesMotorFalse,
  formDataEntriesBothTrue,
} from "../../configurations/config";

//Redux Functions Call
import {
  getCustomers,
  addNewCustomer,
} from "../../redux/reducers/customer/customer.action";
import {
  addNewSP,
  getSP,
} from "../../redux/reducers/serviceProvider/sp.action";
import {
  addNewAgent,
  getAgents,
} from "../../redux/reducers/agents/agents.action";
import { getMySelf } from "../../redux/reducers/user/user.action";
import {
  checkPolicy,
  getPolicyData,
} from "../../redux/reducers/policy/policy.action";

// Helper function Call
import usePolicyCalcFunc from "../../helperFunctions/Policy";
import Remarks from "./Remarks";

const Form = ({ gst, onSubmit }) => {
  const {
    getOneYearAfter,
    formatPolicyNumber,
    formatRegistrationNo,
    calculatePercentage,
  } = usePolicyCalcFunc();
  const calculateDuration = (startDate, expiryDate) => {
    const initialDate = new Date(startDate);
    const endDate = new Date(expiryDate);

    if (!isNaN(initialDate.getTime()) && !isNaN(endDate.getTime())) {
      const durationInMilliseconds = endDate - initialDate;
      const durationInYears = Math.round(
        durationInMilliseconds / (1000 * 60 * 60 * 24 * 365)
      );
      return durationInYears;
    } else {
      return 0;
    }
  };

  const today = moment(new Date()).format("DD-MM-YYYY");
  const duration = calculateDuration(new Date(), getOneYearAfter(new Date()));
  const expiryDate = getOneYearAfter(new Date());

  const initialFormData = {
    insuranceAgent: "",
    insuredName: "",
    customerId: "",
    serviceProviderId: "",
    agentId: "",
    customerName: "",
    insuranceCompany: "",
    insuranceCompanyId: "",
    insuranceType: "",
    policyNo: "",
    paymentType: "",
    issueDate: moment(today, "DD-MM-YYYY").toDate(),
    startDate: moment(today, "DD-MM-YYYY").toDate(),
    duration: duration,
    expiryDate: moment(expiryDate, "DD-MM-YYYY").toDate(),
    netPremium: "",
    gst: "",
    grossPremium: "",
    selfCommissionRate: "",
    agentCommissionRate: "",
    selfCommissionAmt: "",
    agentCommissionAmt: "",
    od: 0,
    tp: 0,
    odSelfCommissionRate: 0,
    tpSelfCommissionRate: 0,
    odAgentCommissionRate: 0,
    tpAgentCommissionRate: 0,
    paidByCustomer: "",
    myContribution: "",
    balance: "",
    paymentMode: "",
    serviceProviderName: "",
    agentName: "",
    registrationNo: "",
    vehicleType: "",
    remarks: "",
  };
  // Create States

  //Object
  const [formData, setFormData] = useState(initialFormData);
  const [updateObj, setUpdateObj] = useState({});
  const [response, setResponse] = useState({});
  const [prevValues, setPrevValues] = useState({
    policyNo: "",
    registrationNo: "",
  });

  // Boolean properties
  const [insuranceAgentField, setInsuranceAgentField] = useState(false);
  const [isMotorInsurance, setIsMotorInsurance] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [reset, setReset] = useState(false);

  // Arrays
  const [uploadedFiles, setUploadedFiles] = useState({
    currentPolicy: [],
    previousYearPolicy: [],
    RC: [],
  });
  const [modalFormType, setModalFormType] = useState([]);

  // Strings
  const [modalTitle, setModalTitle] = useState();
  const [isModalClosing, setIsModalClosing] = useState("");

  // Hooks
  const location = useLocation();
  const formRef = useRef();
  const navigate = useNavigate();

  const handleGetSelf = useAction(getMySelf);
  const handleGetPolicy = useAction(getPolicyData);
  const handleGetAgents = useAction(getAgents);
  const handleGetSp = useAction(getSP);
  const handleGetCustomers = useAction(getCustomers);
  const handleAddCustomerAction = useAction(addNewCustomer);
  const handleAddSpAction = useAction(addNewSP);
  const handleAddAgentAction = useAction(addNewAgent);
  const handleCheckPolicy = useAction(checkPolicy);

  // getting helper functions
  const {
    calcMyContribution,
    calcBalance,
    totalOdTp,
    totalCommissionAmount,
    calculateGrossAmount,
    calculateGrossCommission,
    calculateGst,
  } = usePolicyCalcFunc();

  // confirmation dialog
  const confirmPolicyNoProceed = () => {
    confirmDialog({
      message:
        "Policy already exists with this Policy Number. Do you want to proceed?",
      header: "Warning!",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      reject: () => {
        rejectpolicy(); // Clear the field if rejected
      },
    });
  };

  const rejectpolicy = () => {
    setFormData((prevData) => ({
      ...prevData,
      policyNo: "",
    }));
    setPrevValues((prev) => ({
      ...prev,
      policyNo: "",
      registrationNo: "",
    }));
  };
  const confirmRegistrationNoProceed = () => {
    confirmDialog({
      message:
        "Policy already exists with this Registration Number. Do you want to proceed?",
      header: "Warning!",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      reject: () => {
        rejectregistration(); // Clear the field if rejected
      },
    });
  };

  const rejectregistration = () => {
    setFormData((prevData) => ({
      ...prevData,
      registrationNo: "",
    }));
    setPrevValues((prev) => ({
      policyNo: "",
      registrationNo: "",
    }));
  };
  useEffect(() => {
    const dtToday = new Date(formData.startDate);
    const month = String(dtToday.getMonth() + 1).padStart(2, "0");
    const day = String(dtToday.getDate()).padStart(2, "0");
    const year = dtToday.getFullYear();
    const maxDate = ` ${day}-${month}-${year}`;

    $("#expiryDate").attr("minDate", maxDate);

    const newExpiryDate = getOneYearAfter(dtToday);
    setFormData((prevData) => ({
      ...prevData,
      expiryDate: newExpiryDate,
    }));
  }, [formData.startDate]);

  //update policy code starts
  const { id } = useParams();
  useEffect(() => {
    handleGetPolicy();
  }, [handleGetPolicy]);

  const policyList = useSelector(
    (globalState) => globalState.policyData.policyData
  );

  useEffect(() => {
    setFormData(initialFormData);

    if (id) {
      const policy = policyList.find((item) => item._id === id);
      if (policy) {
        setUpdateObj(policy);
        setFormData((prevData) => ({
          ...prevData,
          ...policy,
          issueDate: moment(policy.issueDate, "DD-MM-YYYY").toDate(),
          startDate: moment(policy.startDate, "DD-MM-YYYY").toDate(),
          expiryDate: moment(policy.expiryDate, "DD-MM-YYYY").toDate(),
        }));
        setInsuranceAgentField(true);
        if (policy.insuranceType === "Motor Insurance") {
          setIsMotorInsurance(true);
        }
        if (policy.insuranceAgent === "Agent") {
          setIsAgent(true);
        }
      }
    } else {
      setInsuranceAgentField(false);
      setIsMotorInsurance(false);
      setIsAgent(false);
    }
  }, [id, location, policyList]);

  //update policy code ends

  // setting default values
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      issueDate: moment(today, "DD-MM-YYYY").toDate(),
      startDate: moment(today, "DD-MM-YYYY").toDate(),
      expiryDate: moment(expiryDate, "DD-MM-YYYY").toDate(),
      duration: duration,
      od: 0,
      tp: 0,
      odSelfCommissionRate: 0,
      tpSelfCommissionRate: 0,
    }));
  }, [today]);

  // Getting data from backend using redux
  useEffect(() => {
    handleGetSelf();
    handleGetCustomers();
    handleGetAgents();
    handleGetSp();
  }, [handleGetSelf, handleGetCustomers, handleGetAgents, handleGetSp]);

  // Handle Form input fields events
  const handleChange = (e) => {
    const isReactSelect = e.hasOwnProperty("name") && e.hasOwnProperty("value");

    const id = isReactSelect ? e.name : e.target.id;
    const value = isReactSelect ? e.value : e.target.value;

    // Ensure the value is a positive integer if it's a number input
    if (!isReactSelect && e.target.type === "number") {
      const isPositiveNumber = (val) => {
        if (val === "") {
          return true; // Allow blank strings
        }
        const num = Number(val); // Convert to number
        return !isNaN(num) && num > 0; // Check if the number is positive
      };

      // Usage:
      if (!isPositiveNumber(value)) {
        return toast.error("Invalid value");
      }
    }

    setFormData((prevData) => {
      let updatedData = { ...prevData, [id]: value };

      // If the input type is date, calculate duration
      if (
        isReactSelect &&
        (e.name === "startDate" || e.name === "expiryDate")
      ) {
        updatedData.duration = calculateDuration(
          id === "startDate" ? value : prevData.startDate,
          id === "expiryDate" ? value : prevData.expiryDate
        );
      }
      if (id === "insuranceAgent") {
        value === "Agent" ? setIsAgent(true) : setIsAgent(false);
      }

      // If the insurance type changes
      if (id === "insuranceType") {
        value === "Motor Insurance"
          ? setIsMotorInsurance(true)
          : setIsMotorInsurance(false);
      }
      // Update premiums and commissions if the input type is number
      if (
        (!isReactSelect && e.target.type === "number") ||
        id === "paymentMode"
      ) {
        if (isMotorInsurance) {
          updatedData.netPremium = totalOdTp(
            id === "od" ? value || 0 : formData.od,
            id === "tp" ? value || 0 : formData.tp
          );
          updatedData.grossPremium = calculateGrossAmount(
            updatedData.netPremium,
            id === "gst" ? value : formData.gst
          );
          updatedData.selfCommissionAmt = totalCommissionAmount(
            id === "odSelfCommissionRate"
              ? value || 0
              : formData.odSelfCommissionRate,
            id === "tpSelfCommissionRate"
              ? value || 0
              : formData.tpSelfCommissionRate,
            id === "od" ? value || 0 : formData.od,
            id === "tp" ? value || 0 : formData.tp
          );
          if (isAgent) {
            updatedData.agentCommissionAmt = totalCommissionAmount(
              id === "odAgentCommissionRate"
                ? value || 0
                : formData.odAgentCommissionRate,
              id === "tpAgentCommissionRate"
                ? value || 0
                : formData.tpAgentCommissionRate,
              id === "od" ? value || 0 : formData.od,
              id === "tp" ? value || 0 : formData.tp
            );
          }
        }

        if (!isMotorInsurance) {
          updatedData.gst = calculateGst(
            id === "netPremium" ? value : prevData.netPremium,
            gst || 18
          );

          updatedData.grossPremium = calculateGrossAmount(
            id === "netPremium" ? value : prevData.netPremium,
            updatedData.gst
          );

          updatedData.selfCommissionAmt = calculateGrossCommission(
            id === "netPremium" ? value : prevData.netPremium,
            id === "selfCommissionRate" ? value : prevData.selfCommissionRate
          );

          // else if (id === "selfCommissionAmt" || id === "netPremium") {
          //   updatedData.selfCommissionRate = calculatePercentage(
          //     id === "netPremium" ? value : prevData.netPremium,
          //     id === "selfCommissionAmt" ? value : prevData.selfCommissionAmt
          //   );
          // }
          if (isAgent) {
            updatedData.agentCommissionAmt = calculateGrossCommission(
              id === "netPremium" ? value : prevData.netPremium,
              id === "agentCommissionRate"
                ? value
                : prevData.agentCommissionRate
            );
          }
        }

        if (updatedData.paymentMode === "Customer") {
          updatedData.paidByCustomer =
            updatedData.grossPremium || formData.grossPremium;
          updatedData.myContribution = 0;

          updatedData.balance = calcBalance(
            updatedData.grossPremium,
            updatedData.paidByCustomer,
            isAgent ? prevData.agentCommissionAmt : 0
          );
        } else if (updatedData.paymentMode === "Self") {
          updatedData.paidByCustomer = 0;
          updatedData.myContribution =
            updatedData.grossPremium || formData.grossPremium;

          updatedData.balance = calcBalance(
            updatedData.grossPremium,
            updatedData.paidByCustomer,
            isAgent ? prevData.agentCommissionAmt : 0
          );
        } else {
          updatedData.grossPremium =
            updatedData.grossPremium || prevData.grossPremium;

          updatedData.myContribution = calcMyContribution(
            updatedData.grossPremium,
            id === "paidByCustomer" ? value : prevData.paidByCustomer
          );

          updatedData.balance = calcBalance(
            updatedData.grossPremium,
            id === "paidByCustomer" ? value : prevData.paidByCustomer,
            isAgent ? prevData.agentCommissionAmt : 0
          );
        }
      }
      return updatedData;
    });
  };

  const handleBlur = useCallback(
    (e) => {
      const isReactSelect =
        e.hasOwnProperty("name") && e.hasOwnProperty("value");
      const id = isReactSelect ? e.name : e.target.id;
      const value = isReactSelect ? e.value : e.target.value;

      // Directly update formData
      setFormData((prevData) => {
        let updatedData = { ...prevData, [id]: value };

        // Check for policy number
        if (id === "policyNo" && value !== prevValues.policyNo) {
          updatedData.policyNo = formatPolicyNumber(value);
          if (updatedData.policyNo !== "") {
            handleCheckPolicy({ policyNo: updatedData.policyNo }).then(
              (action) => {
                if (action.type === "CHECK_POLICY") {
                  const exists = action.payload;

                  if (exists) {
                    confirmPolicyNoProceed();
                  }
                }
              }
            );
          }
        }

        // Check for registration number
        if (id === "registrationNo" && value !== prevValues.registrationNo) {
          updatedData.registrationNo = formatRegistrationNo(value);
          if (updatedData.registrationNo !== "") {
            handleCheckPolicy({
              registrationNo: updatedData.registrationNo,
            }).then((action) => {
              if (action.type === "CHECK_POLICY") {
                const exists = action.payload;
                if (exists) {
                  confirmRegistrationNoProceed();
                }
              }
            });
          }
        }

        return updatedData;
      });

      // Update previous values state outside of formData update to ensure consistency
      if (value !== prevValues[id]) {
        setPrevValues((prev) => ({
          ...prev,
          [id]: value,
        }));
      }
    },
    [prevValues, handleCheckPolicy]
  );

  const handleCustomerChange = (event) => {
    const selectedOption = event.target;
    if (!selectedOption) {
      console.error("Selected option is undefined or null.");
      return;
    }

    const selectedValue = selectedOption.value;
    const selectedName = selectedOption.dataset.name || "";

    switch (selectedOption.name) {
      case "insuranceCompanyId":
        setFormData((prevFormData) => ({
          ...prevFormData,
          insuranceCompanyId: selectedValue,
          insuranceCompany: selectedName,
        }));
        break;
      case "customerId":
        setFormData((prevFormData) => ({
          ...prevFormData,
          customerId: selectedValue,
          customerName: selectedName,
        }));
        break;
      case "serviceProviderId":
        setFormData((prevFormData) => ({
          ...prevFormData,
          serviceProviderId: selectedValue,
          serviceProviderName: selectedName,
        }));
        break;
      case "agentId":
        setFormData((prevFormData) => ({
          ...prevFormData,
          agentId: selectedValue,
          agentName: selectedName,
        }));
        break;
      default:
        console.warn(`Unknown target name: ${event.target.name}`);
        break;
    }
  };

  const handleFilesAdded = (newFiles, fileType) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fileType]: [...prevFiles[fileType], ...newFiles],
    }));
  };

  const handleFileRemoved = (fileToRemove, fileType) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fileType]: prevFiles[fileType].filter((file) => file !== fileToRemove),
    }));
  };
  const resetFiles = () => {
    setUploadedFiles({
      currentPolicy: [],
      previousYearPolicy: [],
      RC: [],
    });
    setReset(true);
    setTimeout(() => setReset(false), 0);
  };

  const handleSubmit = async (e) => {
    // console.log(formData);
    e.preventDefault();

    const formdata = new FormData(formRef.current);
    formdata.append("serviceProviderName", formData.serviceProviderName);
    formdata.append("insuranceCompany", formData.insuranceCompany);

    if (!isAgent && !isMotorInsurance) {
      formdata.append("customerName", formData.customerName);
      for (const [key, value] of Object.entries(formDataEntriesBothFalse)) {
        formdata.append(key, value);
      }
    } else if (!isAgent && isMotorInsurance) {
      formdata.append("customerName", formData.customerName);
      for (const [key, value] of Object.entries(formDataEntriesAgentFalse)) {
        formdata.append(key, value);
      }
    } else if (isAgent && !isMotorInsurance) {
      formdata.append("customerName", formData.agentName);
      for (const [key, value] of Object.entries(formDataEntriesMotorFalse)) {
        formdata.append(key, value);
      }
    } else if (isAgent && isMotorInsurance) {
      formdata.append("customerName", formData.agentName);
      for (const [key, value] of Object.entries(formDataEntriesBothTrue)) {
        formdata.append(key, value);
      }
    }

    if (!isMotorInsurance) {
      if (formData.selfCommissionRate) {
        const odSelfCommissionRate = formData.selfCommissionRate;
        const tpSelfCommissionRate = formData.selfCommissionRate;
        formdata.append("odSelfCommissionRate", odSelfCommissionRate);
        formdata.append("tpSelfCommissionRate", tpSelfCommissionRate);
      }
      if (formData.agentCommissionRate) {
        const odAgentCommissionRate = formData.agentCommissionRate;
        const tpAgentCommissionRate = formData.agentCommissionRate;
        formdata.append("odAgentCommissionRate", odAgentCommissionRate);
        formdata.append("tpAgentCommissionRate", tpAgentCommissionRate);
      }
    }

    if (!_.isEmpty(updateObj)) {
      formdata.append("_id", formData._id);
    }

    // Check if "Current Policy" file is present
    if (uploadedFiles.currentPolicy.length === 0) {
      toast.error("Current Policy file is required");
      return;
    }
    Object.entries(uploadedFiles).forEach(([fileType, files]) => {
      files.forEach((file) => {
        formdata.append(fileType, file);
      });
    });
    onSubmit(formdata);
    setFormData(initialFormData);
    resetFiles();
  };

  const handleAddCustomer = () => {
    setModalFormType(customerFormInputFields);
    setModalTitle("Add Customer");
    setShowModal(true);
  };

  const handleAddSP = () => {
    setModalFormType(spFormInputFields);
    setModalTitle("Add Service Provider");
    setShowModal(true);
  };
  const handleAddAgent = () => {
    setModalFormType(agentFormInputFields);
    setModalTitle("Add Agent");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handleGetCustomers();
  };

  const customerList = useSelector(
    (globalState) => globalState.customerReducer.customers
  );

  const spList = useSelector(
    (globalState) => globalState.spReducer.serviceProviders
  );

  const agentsList = useSelector(
    (globalState) => globalState.agentsReducer.agents
  );

  useEffect(() => {
    if (isModalClosing === "Customer") {
      setFormData((prevData) => ({
        ...prevData,
        customerId: response._id,
        customerName: response.customerName,
      }));
      setIsModalClosing(false);
      setResponse({});
    } else if (isModalClosing === "SP") {
      setFormData((prevData) => ({
        ...prevData,
        serviceProviderId: response._id,
        serviceProviderName: response.serviceProviderName,
      }));
      setIsModalClosing(false);
    } else if (isModalClosing === "Agent") {
      setFormData((prevData) => ({
        ...prevData,
        agentId: response._id,
        agentName: response.agentName,
      }));
      setIsModalClosing(false);
    }
  }, [isModalClosing]);

  const handleCustomerSave = async () => {
    setShowModal(false);
    await handleGetCustomers();
    setIsModalClosing("Customer");
  };
  const handleSpSave = async () => {
    setShowModal(false);
    await handleGetSp();
    setIsModalClosing("SP");
  };
  const handleAgentSave = async () => {
    setShowModal(false);
    await handleGetAgents();
    setIsModalClosing("Agent");
  };
  const handleSaveFormData = (formData) => {
    if ("customerName" in formData) {
      const response = handleAddCustomerAction(formData);
      response.then(function (result) {
        setResponse(result.payload.addedData);
      });
      handleGetCustomers();
      handleCustomerSave();
    } else if ("agentName" in formData) {
      const response = handleAddAgentAction(formData);
      response.then(function (result) {
        setResponse(result.payload.addedData);
      });
      handleGetAgents();
      handleAgentSave();
    } else if ("serviceProviderName" in formData) {
      const response = handleAddSpAction(formData);
      response.then(function (result) {
        setResponse(result.payload.addedData);
      });
      handleGetSp();
      handleSpSave();
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    resetFiles();
  };
  return (
    <>
      <div className="container my-5">
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          ref={formRef}
        >
          <div className="card">
            <h2 className="m-3 text-primary">New Policy</h2>
            <div className="card card-body m-3 shadow-sm">
              <div className="  card-body p-3">
                <BasicDetails
                  handleChange={handleChange}
                  formData={formData}
                  isMotorInsurance={isMotorInsurance}
                  handleAddCustomer={handleAddCustomer}
                  handleAddSP={handleAddSP}
                  handleAddAgent={handleAddAgent}
                  handleCustomerChange={handleCustomerChange}
                  customerList={customerList}
                  spList={spList}
                  agentsList={agentsList}
                  isAgent={isAgent}
                  insuranceAgentField={insuranceAgentField}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            <div className="card card-body m-3 shadow-sm">
              <div className="card-body p-3">
                <PolicyDates formData={formData} handleChange={handleChange} />
              </div>
            </div>
            <div className="card card-body m-3 shadow-sm">
              <div className="card-body p-3">
                <PolicyPremiums
                  formData={formData}
                  handleChange={handleChange}
                  isMotorInsurance={isMotorInsurance}
                  isAgent={isAgent}
                />
              </div>
            </div>
            <div className="card card-body m-3 shadow-sm">
              <div className="card-body p-3">
                <Remarks formData={formData} handleChange={handleChange} />
              </div>
            </div>
            <div className="card card-body m-3 shadow-sm">
              <h5 className="card-header">Upload Files:</h5>
              <div className="card-body p-3 row">
                <div className="col">
                  <FileDropzone
                    className="w-full"
                    onFilesAdded={handleFilesAdded}
                    onFileRemoved={handleFileRemoved}
                    label={"Current Policy"}
                    fileType={"currentPolicy"}
                    reset={reset}
                  />
                </div>

                <div className="col">
                  <FileDropzone
                    className="w-full"
                    onFilesAdded={handleFilesAdded}
                    onFileRemoved={handleFileRemoved}
                    label={"Previous Year Policy"}
                    fileType={"previousYearPolicy"}
                    reset={reset}
                  />
                </div>
                <div className="col">
                  <FileDropzone
                    className="w-full"
                    onFilesAdded={handleFilesAdded}
                    onFileRemoved={handleFileRemoved}
                    label={"RC"}
                    fileType={"RC"}
                    reset={reset}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 card-body d-flex justify-content-end">
              {!id ? (
                <button
                  type="button"
                  class="btn btn-outline-secondary mx-3"
                  onClick={resetForm}
                >
                  Reset
                </button>
              ) : (
                <button
                  type="button"
                  class="btn btn-outline-secondary mx-3"
                  onClick={() => navigate("/dashboard/policyList")}
                >
                  Cancel
                </button>
              )}

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>

        <Modal
          show={showModal}
          title={modalTitle}
          onClose={handleCloseModal}
          inputFields={modalFormType}
          handleSaveFormData={handleSaveFormData}
        ></Modal>
      </div>
    </>
  );
};

export default Form;
