import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const LedgerTable = ({ ledgerArray, handleCollection, basedOn }) => {
  const [title, setTitle] = useState("Collected");
  const navigate = useNavigate();

  useEffect(() => {
    if (basedOn === "agentName") {
      setTitle("Paid");
    }
  }, []);

  const handleRedirect = (id, basedOn) => {
    navigate(`/dashboard/ledgers/search?id=${id}&basedOn=${basedOn}`);
  };
  return (
    <>
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <th scope="col">Serial No.</th>
            <th scope="col">Name</th>
            <th scope="col">No. of Policy</th>
            <th scope="col">Gross Premium</th>
            {basedOn !== "serviceProviderName" ? (
              <th scope="col">Paid By Customer</th>
            ) : null}
            {basedOn !== "serviceProviderName" ? (
              <th scope="col">My Contribution</th>
            ) : null}
            <th scope="col">Reward Amount</th>
            {basedOn !== "serviceProviderName" ? (
              <th scope="col">Balance</th>
            ) : null}
            <th scope="col">Carry Forward Amount</th>
            <th scope="col">{title}</th>
            <th scope="col">Final Balance</th>
            <th scope="col">Update</th>
          </tr>
        </thead>
        <tbody>
          {ledgerArray &&
            ledgerArray.map((policy, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td
                  onClick={() => handleRedirect(policy.id, basedOn)}
                  role="button"
                  className="text-primary fw-bold"
                >
                  {policy.name}
                </td>
                <td>{policy.noOfPolicies}</td>
                <td>{policy.totalGrossPremium.toFixed()}</td>
                {basedOn !== "serviceProviderName" && (
                  <td>{policy.totalPaidByCustomer.toFixed()}</td>
                )}
                {basedOn !== "serviceProviderName" && (
                  <td>{policy.totalMyContribution.toFixed()}</td>
                )}
                <td>{policy.totalRewardAmount.toFixed()}</td>
                {basedOn !== "serviceProviderName" && (
                  <td>{policy.totalCommission.toFixed()}</td>
                )}
                <td>{policy.openingBalance.toFixed()}</td>
                <td>{policy.totalCollected.toFixed()}</td>
                <td>{policy.balance.toFixed()}</td>
                <td>
                  {policy.id ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm mx-2"
                      onClick={() => handleCollection(policy.name, policy.id)}
                    >
                      Update
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default LedgerTable;
