import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signUp } from "../redux/reducers/auth/auth.action";
import { getMySelf } from "../redux/reducers/user/user.action";
import { useAction } from "../helperFunctions/useAction";
import { toast } from "react-toastify";

const SignUp = () => {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    username: false,
    email: false,
    phoneNumber: false,
    password: false,
    confirmPassword: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleGetSelf = useAction(getMySelf);
  const handleSignUp = useAction(signUp);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUserData((prev) => ({ ...prev, [id]: value }));

    // Validate on change
    validateField(id, value);
  };

  const validateField = (id, value) => {
    let isValid = true;

    if (id === "phoneNumber") {
      const phonePattern = /^[0-9]{10}$/;
      isValid = phonePattern.test(value);
    } else if (id === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      isValid = emailPattern.test(value);
    } else if (id === "password") {
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      isValid = passwordPattern.test(value);
    } else if (id === "confirmPassword") {
      isValid = value === userData.password;
    } else {
      isValid = value.trim() !== "";
    }

    setErrors((prev) => ({ ...prev, [id]: !isValid }));
  };

  const handleSubmit = async () => {
    const { username, email, phoneNumber, password, confirmPassword } =
      userData;
    const newErrors = {
      username: username.trim() === "",
      email: !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email),
      phoneNumber: !/^[0-9]{10}$/.test(phoneNumber),
      password:
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          password
        ),
      confirmPassword: confirmPassword !== password,
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      toast.error("Please fill out all fields correctly.");
      return;
    }

    try {
      await handleSignUp(userData);
      await handleGetSelf();

      setUserData({
        username: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
      });
      setErrors({
        username: false,
        email: false,
        phoneNumber: false,
        password: false,
        confirmPassword: false,
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        alert("An error occurred while signing up.");
      }
    }
  };

  return (
    <div className="register_bg">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6 signup_card">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="col-xl-6 col-md-6 sign text-center">
                      <div>
                        <div className="text-start m-3">
                          <div className="logo">
                            <span className="text-white fw-bold fs-1">
                              BimaBook
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 className="text-center text-white mb-4">
                      Sign up your account
                    </h4>
                    <form>
                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="username" className="mb-1">
                          <strong>Username</strong>
                        </label>
                        <input
                          type="text"
                          id="username"
                          className={`form-control py-2 ${
                            errors.username ? "is-invalid" : ""
                          }`}
                          placeholder="Username"
                          value={userData.username}
                          onChange={handleChange}
                          required
                        />
                        {errors.username && (
                          <div className="invalid-feedback text-white">
                            Username is required.
                          </div>
                        )}
                      </div>
                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="phoneNumber" className="mb-1">
                          <strong>Phone Number</strong>
                        </label>
                        <input
                          type="tel"
                          pattern="[0-9]{10}"
                          maxLength="10"
                          id="phoneNumber"
                          className={`form-control py-2 ${
                            errors.phoneNumber ? "is-invalid" : ""
                          }`}
                          placeholder="99XXXXXXXX"
                          value={userData.phoneNumber}
                          onChange={handleChange}
                          required
                        />
                        {errors.phoneNumber && (
                          <div className="invalid-feedback text-white">
                            Please enter a valid 10-digit phone number.
                          </div>
                        )}
                      </div>
                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="email" className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          id="email"
                          className={`form-control py-2 ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          placeholder="hello@example.com"
                          value={userData.email}
                          onChange={handleChange}
                          required
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                        />
                        {errors.email && (
                          <div className="invalid-feedback text-white">
                            Please enter a valid email address.
                          </div>
                        )}
                      </div>
                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="password" className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className={`form-control py-2 ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          placeholder="Password"
                          value={userData.password}
                          onChange={handleChange}
                          required
                          pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
                        />
                        {errors.password && (
                          <div className="invalid-feedback text-white">
                            Password must be at least 8 characters long, contain
                            at least one uppercase letter, one lowercase letter,
                            one digit, and one special character.
                          </div>
                        )}
                      </div>
                      <div className="mb-3 text-white mx-5">
                        <label htmlFor="confirmPassword" className="mb-1">
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="confirmPassword"
                          className={`form-control py-2 ${
                            errors.confirmPassword ? "is-invalid" : ""
                          }`}
                          placeholder="Confirm Password"
                          value={userData.confirmPassword}
                          onChange={handleChange}
                          required
                        />
                        {errors.confirmPassword && (
                          <div className="invalid-feedback text-white">
                            Passwords do not match.
                          </div>
                        )}
                      </div>
                      <div className="form-check text-white mx-5">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="showPassword"
                          checked={showPassword}
                          onChange={() => setShowPassword(!showPassword)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="showPassword"
                        >
                          Show Password
                        </label>
                      </div>
                      <div className="text-center mt-4 mx-5">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-primary btn-block form-control py-3"
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3 mx-5">
                      <p className="text-white">
                        Already have an account?{" "}
                        <Link to="/" className="text-primary">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
