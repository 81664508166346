import {
  ADD_AGENTS,
  DELETE_AGENTS,
  GET_AGENTS,
  UPDATE_AGENTS,
} from "./agents.type";

const initialState = {
  agents: [],
};

const agentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENTS:
      return {
        ...state,
        agents: action.payload,
      };
    case ADD_AGENTS:
      return {
        ...state,
      };
    case DELETE_AGENTS:
      return {
        ...state,
      };
    case UPDATE_AGENTS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default agentsReducer;
