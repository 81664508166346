import React, { useEffect, useState } from "react";
import selectFieldData from "../configurations/selectFieldData.json";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarMonth } from "react-icons/md";

const CustomerForm = ({
  onClose,
  company,
  onFormSubmit,
  basedOnName,
  basedOnId,
  updateData,
  keyId,
}) => {
  const [formData, setFormData] = useState({
    toBank: "",
    basicAmount: "",
    tds: "",
    afterTds: "",
    dateOfCollection: new Date(),
    collectedForMonth: new Date(),
  });

  useEffect(() => {
    const insuranceCompany = company;
    const id = keyId;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [basedOnName]: insuranceCompany,
      [basedOnId]: id,
    }));
  }, [company, basedOnName, basedOnId]);

  useEffect(() => {
    if (updateData !== null) {
      setFormData((prevState) => ({
        ...prevState,
        ...updateData,
      }));
    }
  }, [updateData]);

  const calculateGrossAmount = (netPremium, gst) => {
    if (netPremium && gst) {
      const net = parseFloat(netPremium);
      const percent = parseFloat(gst);
      const amount = net * (percent / 100);
      const gross = net - amount;
      return gross.toFixed(2);
    }
    return "";
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [id]: value };
      if (id === "basicAmount" || id === "tds") {
        updatedData.afterTds = calculateGrossAmount(
          id === "basicAmount" ? value : formData.basicAmount,
          id === "tds" ? value : formData.tds
        );
      }
      return updatedData;
    });
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfCollection: date,
    }));
  };

  const handleMonthChange = (date) => {
    const newDate = new Date(date);
    newDate.setDate(1);
    setFormData((prevData) => ({
      ...prevData,
      collectedForMonth: newDate,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (onFormSubmit) {
      onFormSubmit(formData);
    }
    if (onClose) {
      onClose();
    }
    setFormData({
      toBank: "",
      basicAmount: "",
      tds: "",
      afterTds: "",
      dateOfCollection: "",
      collectedForMonth: "",
    });
  };

  return (
    <div className="container my-5">
      <form className="row g-4" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <label htmlFor="toBank" className="form-label">
            To Bank Account<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="toBank"
            value={formData.toBank}
            onChange={handleChange}
            placeholder="Enter bank account"
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="basicAmount" className="form-label">
            Basic Amount<span className="text-danger">*</span>
          </label>
          <input
            type="number"
            min="0"
            step={0.01}
            className="form-control"
            id="basicAmount"
            value={formData.basicAmount}
            onChange={handleChange}
            required
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="tds" className="form-label">
            TDS %<span className="text-danger">*</span>
          </label>
          <input
            type="number"
            min="0"
            className="form-control"
            id="tds"
            value={formData.tds}
            onChange={handleChange}
            required
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="afterTds" className="form-label">
            After TDS<span className="text-danger">*</span>
          </label>
          <input
            type="number"
            className="form-control"
            id="afterTds"
            value={formData.afterTds}
            onChange={handleChange}
            disabled
            required
          />
        </div>
        <div className="col-md-6 d-flex flex-column">
          <label htmlFor="dateOfCollection" className="form-label">
            Date of Collection<span className="text-danger">*</span>
          </label>
          <DatePicker
            className="form-control"
            id="dateOfCollection"
            selected={new Date(formData.dateOfCollection)}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            showIcon
            icon={<MdCalendarMonth className="text-primary fs-5" />}
            placeholderText="yyyy-MM-dd"
            required
          />
        </div>
        <div className="col-md-6 d-flex flex-column">
          <label htmlFor="collectedForMonth" className="form-label">
            Collected for Month<span className="text-danger">*</span>
          </label>
          <DatePicker
            className="form-control"
            id="collectedForMonth"
            selected={new Date(formData.collectedForMonth)}
            onChange={handleMonthChange}
            dateFormat="yyyy-MM"
            placeholderText="yyyy-MM"
            showMonthYearPicker
            dropdownMode="select"
            showIcon
            icon={<MdCalendarMonth className="text-primary fs-5" />}
            required
          />
        </div>
        <div className="col-12">
          <button className="btn btn-primary" type="submit">
            {_.isEmpty(updateData) ? "Save" : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomerForm;
