// Custom hook to manage counter logic

export default function usePolicyCalcFunc() {
  const calcMyContribution = (grossPremium, paidByCustomer) => {
    const gross = parseFloat(grossPremium) || 0;
    const paid = parseFloat(paidByCustomer) || 0;
    return (gross - paid).toFixed(2);
  };

  const calcBalance = (grossPremium, paidByCustomer, commissionAmt) => {
    const gross = parseFloat(grossPremium) || 0;
    const paid = parseFloat(paidByCustomer) || 0;
    const commission = parseFloat(commissionAmt) || 0;
    const contribution = gross - paid;
    return (commission - contribution).toFixed(2);
  };

  const totalOdTp = (od, tp) => {
    const odValue = parseFloat(od) || 0;
    const tpValue = parseFloat(tp) || 0;
    return (odValue + tpValue).toFixed(2);
  };

  const totalCommissionAmount = (rate1, rate2, od, tp) => {
    const commissionOnOd = calculateGrossCommission(
      parseFloat(od),
      parseFloat(rate1)
    );
    const commissionOnTp = calculateGrossCommission(
      parseFloat(tp),
      parseFloat(rate2)
    );
    return (parseFloat(commissionOnOd) + parseFloat(commissionOnTp)).toFixed(2);
  };

  const calculateGrossAmount = (netPremium, gst) => {
    const net = parseFloat(netPremium) || 0;
    const gstAmt = parseFloat(gst) || 0;
    const gross = net + gstAmt;
    return gross.toFixed(2);
  };

  const calculateGst = (netPremium, gst) => {
    const net = parseFloat(netPremium) || 0;
    const percent = parseFloat(gst) || 0;
    const gstAmt = net * (percent / 100);
    return gstAmt.toFixed(2);
  };

  const calculateGrossCommission = (netPremium, commissionRate) => {
    const net = parseFloat(netPremium) || 0;
    const percent = parseFloat(commissionRate) || 0;
    return (net * (percent / 100)).toFixed(2);
  };

  const getOneYearAfter = (date) => {
    // Create a new Date object from the input date
    let newDate = new Date(date);

    // Set the year to one year after the given date
    newDate.setFullYear(newDate.getFullYear() + 1);

    return newDate;
  };
  const calculatePercentage = (net, amount) => {
    if (amount === 0) {
      throw new Error("The whole value cannot be zero.");
    }
    const result = (amount / net) * 100;
    return result.toFixed(2);
  };
  // Date Formatting
  const formatDate = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return [day, month, year].join("-");
  };
  const formatRegistrationNo = (value) => {
    // Remove all non-alphanumeric characters
    const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
    // Convert to uppercase
    return alphanumericValue.toUpperCase();
  };
  const formatPolicyNumber = (value) => {
    // Remove all spaces
    const noSpaceValue = value.replace(/\s+/g, "");
    // Convert to uppercase
    return noSpaceValue.toUpperCase();
  };
  return {
    calcMyContribution,
    calcBalance,
    totalOdTp,
    totalCommissionAmount,
    getOneYearAfter,
    calculateGrossAmount,
    calculateGrossCommission,
    calculateGst,
    formatDate,
    formatRegistrationNo,
    formatPolicyNumber,
    calculatePercentage,
  };
}
