import React from "react";
import "../css/welcome.css";

const Welcome = () => {
  return (
    <div className="welcome-container ">
      <div className="welcome-message">
        <h1>Welcome to Our BimaBook</h1>
        <p>We are glad to have you here.</p>
        <button className="explore-button">Explore Now</button>
      </div>
    </div>
  );
};

export default Welcome;
