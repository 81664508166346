import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Welcome from "../components/Welcome";

// components Calling
import Navbar from "../components/Navbar";
import RenewalsBanner from "../components/renewals/RenewalsBanner";
import SidebarPro from "../components/SidebarPro";

const Dashboard = () => {
  const location = useLocation();
  return (
    <>
      <div className="h-100">
        <RenewalsBanner />
        <Navbar />
        <div className="d-flex">
          <SidebarPro className="fixed-sidebar" />
          <div className="d-flex flex-column content">
            <div className="">
              {location.pathname === "/dashboard" && <Welcome />}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
