import {
  ADD_CUSTOMER,
  GET_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
} from "./customer.type";

const initialState = {
  customers: [],
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER:
      return {
        ...state,
        customers: action.payload,
      };
    case ADD_CUSTOMER:
      return {
        ...state,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default customerReducer;
