import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import "datatables.net";
import "datatables.net-dt/css/dataTables.dataTables.css";
import $ from "jquery";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import ReactDOMServer from "react-dom/server";
import { FaRegFilePdf } from "react-icons/fa";
const jzip = require("jzip");
require("datatables.net-buttons/js/buttons.html5.min");
require("datatables.net-buttons/js/buttons.print.min");
require("datatables.net-buttons/js/dataTables.buttons.min");
require("datatables.net-buttons/js/buttons.colVis.min.mjs");

window.JSZip = jzip;

const ReactDataTable = ({
  array,
  columnsToShow,
  dataTableButtonsConfig,
  filterableColumns,
  showFilter = false,
  searching = true,
  paging = true,
  ordering = true,
  includeCurrentPolicy = false,
}) => {
  const [tableArray, setTableArray] = useState([]);
  const [theme, setTheme] = useState("light");
  const [error, setError] = useState(null);
  const tableRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (Array.isArray(array)) {
      setTableArray(array);
      setError(null);
    } else {
      setError("Invalid data type of Data Array. Expected an array");
    }
  }, [array]);

  useEffect(() => {
    const columnsCopy = [...columnsToShow]; // Make a copy to avoid direct mutation

    // Conditionally add the "Current Policy" column
    if (
      includeCurrentPolicy &&
      !columnsCopy.some((column) => column.title === "Current Policy")
    ) {
      columnsCopy.unshift({
        title: "Current Policy",
        data: null,
        render: function (data, type, row) {
          const url = row.currentPolicyPath;
          if (type === "display") {
            // For display purposes
            return ReactDOMServer.renderToStaticMarkup(
              <div className="d-flex justify-content-center">
                <a
                  href={url}
                  target="_blank"
                  title="Download PDF"
                  className="px-3"
                >
                  <FaRegFilePdf className="fs-5 text-danger" />
                </a>
              </div>
            );
          } else if (type === "export") {
            // For export purposes
            return url;
          }
          return "";
        },
      });
    }

    if (
      dataTableButtonsConfig &&
      dataTableButtonsConfig.length > 0 &&
      !columnsCopy.some((column) => column.title === "Actions")
    ) {
      columnsCopy.unshift({
        title: "Actions",
        data: null,
        render: (data, type, row) =>
          dataTableButtonsConfig
            .map((button, index) => {
              const IconComponent = button.icon;
              const buttonClass = button.className || "text-primary"; // Default Bootstrap class if none provided
              const rowData = encodeURIComponent(JSON.stringify(row));
              return ReactDOMServer.renderToStaticMarkup(
                IconComponent ? (
                  <IconComponent
                    key={index}
                    className={`btn-action ${buttonClass}`}
                    data-index={index}
                    data-id={row._id}
                    data-row={rowData}
                    title={button.label}
                    style={{ cursor: "pointer", margin: "0 5px" }}
                  />
                ) : (
                  <button
                    key={index}
                    className={`btn-action rounded-2 p-1 ${buttonClass}`}
                    data-index={index}
                    data-id={row._id}
                  >
                    {button.label}
                  </button>
                )
              );
            })
            .join(" "),
      });
    }
    if (!columnsCopy.some((column) => column.title === "Sr. No.")) {
      columnsCopy.unshift({
        title: "Sr. No.",
        data: null,
        render: function (data, type, row, meta) {
          return meta.row + 1;
        },
      });
    }

    const table = $(tableRef.current).DataTable({
      data: tableArray,
      columns: columnsCopy,
      searching,
      paging,
      ordering,
      bJQueryUI: true,
      scrollX: true,
      layout: {
        topStart: {
          buttons: [
            {
              extend: "collection",
              text: "Export",
              buttons: [
                {
                  extend: "copy",
                  exportOptions: {
                    orthogonal: "export",
                    columns: function (idx, data, node) {
                      const table = $(tableRef.current).DataTable();
                      const visibleColumns = table
                        .columns(":visible")
                        .indexes()
                        .toArray();
                      return idx !== 0 && visibleColumns.includes(idx); // Exclude the first column
                    },
                  },
                },
                {
                  extend: "excel",
                  title: "",
                  exportOptions: {
                    orthogonal: "export",
                    columns: function (idx, data, node) {
                      const table = $(tableRef.current).DataTable();
                      const visibleColumns = table
                        .columns(":visible")
                        .indexes()
                        .toArray();
                      return idx !== 0 && visibleColumns.includes(idx); // Exclude the first column
                    },
                  },
                },
                {
                  extend: "csv",
                  exportOptions: {
                    orthogonal: "export",
                    columns: function (idx, data, node) {
                      const table = $(tableRef.current).DataTable();
                      const visibleColumns = table
                        .columns(":visible")
                        .indexes()
                        .toArray();
                      return idx !== 0 && visibleColumns.includes(idx); // Exclude the first column
                    },
                  },
                },
                {
                  extend: "print",
                  exportOptions: {
                    orthogonal: "export",
                    columns: function (idx, data, node) {
                      const table = $(tableRef.current).DataTable();
                      const visibleColumns = table
                        .columns(":visible")
                        .indexes()
                        .toArray();
                      return idx !== 0 && visibleColumns.includes(idx); // Exclude the first column
                    },
                  },
                },
              ],
            },
            "pageLength",
            {
              popoverTitle: "Visibility control",
              extend: "colvis",
              collectionLayout: "two-column",
              postfixButtons: ["colvisRestore"],
            },
            {
              text: "Toggle Theme",
              action: function () {
                setTheme((prevTheme) =>
                  prevTheme === "light" ? "dark" : "light"
                );
              },
            },
          ],
        },
      },

      stateSave: true,
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(
          "DataTables_" + location.pathname,
          JSON.stringify(data)
        );
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(
          localStorage.getItem("DataTables_" + location.pathname)
        );
      },
      stateDuration: 24,
      destroy: true,

      initComplete: function () {
        if (showFilter) {
          const tfoot = $("<tfoot><tr></tr></tfoot>");
          const footerRow = tfoot.find("tr");
          filterableColumns.forEach((key) => {
            const uniqueValues = [
              ...new Set(tableArray.map((item) => item[key])),
            ];
            const select = $(`<select><option value="">All</option></select>`);
            uniqueValues.forEach((value) => {
              select.append(`<option value="${value}">${value}</option>`);
            });
            footerRow.append($("<th>").append(select));
          });
          footerRow.append("<th></th>");
          $(tableRef.current).append(tfoot);

          $(tfoot)
            .find("select")
            .on("change", function () {
              const columnIndex = $(this).parent().index();
              table.column(columnIndex).search(this.value).draw();
            });
        }
      },
    });

    $(tableRef.current)
      .off("click", ".btn-action")
      .on("click", ".btn-action", function () {
        const buttonIndex = $(this).data("index");
        const rowData = JSON.parse(decodeURIComponent($(this).data("row")));
        if (dataTableButtonsConfig[buttonIndex]?.onClick) {
          dataTableButtonsConfig[buttonIndex].onClick(rowData);
        }
      });

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
        $(tableRef.current).empty();
      }
    };
  }, [
    tableArray,
    columnsToShow,
    dataTableButtonsConfig,
    showFilter,
    searching,
    paging,
    ordering,
    filterableColumns,
    location.pathname,
  ]);

  if (error) {
    console.warn(error);
  }
  return (
    <div id="react_table">
      <table
        className="table table-striped table-hover cell-border nowrap"
        data-bs-theme={theme}
        width="100%"
        ref={tableRef}
      ></table>
    </div>
  );
};

export default ReactDataTable;
