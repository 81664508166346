import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPolicyData } from "../../redux/reducers/policy/policy.action";
import { getCustomers } from "../../redux/reducers/customer/customer.action";
import { useAction } from "../../helperFunctions/useAction";

const RenewalsList = () => {
  const [renewals, setRenewals] = useState([]);
  const [expiredPolicies, setExpiredPolicies] = useState([]);
  const handleGetPolicy = useAction(getPolicyData);
  const handleGetCustomers = useAction(getCustomers);

  useEffect(() => {
    handleGetPolicy();
    handleGetCustomers();
  }, [handleGetPolicy, handleGetCustomers]);

  const customers = useSelector(
    (globalState) => globalState.customerReducer.customers
  );
  const policyList = useSelector(
    (globalState) => globalState.policyData.policyData
  );

  useEffect(() => {
    if (Array.isArray(policyList)) {
      const newRenewals = [];
      const expiredList = [];
      const today = new Date();

      policyList.forEach((item) => {
        const { expiryDate } = item;
        const expiryDateObj = new Date(expiryDate);

        // Compare if today is within the 2 days range to expiry date
        const twoDaysBeforeExpiry = new Date(expiryDateObj);
        twoDaysBeforeExpiry.setDate(expiryDateObj.getDate() - 30);

        if (today >= twoDaysBeforeExpiry) {
          newRenewals.push(item);
        }
        if (today > expiryDateObj) {
          expiredList.push(item);
        }
      });

      setRenewals(newRenewals);
      setExpiredPolicies(expiredList);
    } else {
      console.warn("Invalid data provided:", policyList);
    }
  }, [policyList]);

  if (renewals.length > 0) {
    return (
      <div className="container_width">
        <div className="d-flex justify-content-center">
          <h3 className="text-primary">Renewals</h3>
        </div>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Customer Name</th>
              <th>Policy Number</th>
              <th>Registration Number</th>
              <th>Expiry Date</th>
              <th>Phone Number</th>
              <th>Insurance Company</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {renewals.map((item1, index) => {
              const item2 = customers.find(
                (item) => item._id === item1.customerId
              );

              const isExpired = expiredPolicies.some(
                (expiredItem) => expiredItem._id === item1._id
              );

              return (
                <tr key={index} className={isExpired ? "table-danger" : ""}>
                  <td>{index + 1}</td>
                  <td>{item1.customerName}</td>
                  <td>{item1.policyNo}</td>
                  <td>
                    {item1.RegistrationNo ? item1.RegistrationNo : "Non-Motor"}
                  </td>
                  <td>{item1.expiryDate}</td>
                  <td>{item2 ? item2.phoneNumber : "Not provided"}</td>
                  <td>{item1.insuranceCompany}</td>
                  <td>
                    {isExpired ? (
                      <span className="badge bg-danger">Expired</span>
                    ) : (
                      "Active"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center text-danger fs-3 fw-bold container_width">
        No Renewals Available
      </div>
    );
  }
};

export default RenewalsList;
