import React, { useEffect, useState } from "react";
import _ from "lodash";
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import { MdCalendarMonth } from "react-icons/md";
import { toast } from "react-toastify";
import usePolicyCalcFunc from "../../helperFunctions/Policy";
import moment from "moment";

const MasterForm = ({ handleSaveFormData, inputFields, updateData }) => {
  const [formData, setFormData] = useState({});
  const [switchStatus, setSwitchStatus] = useState(true);
  const [errors, setErrors] = useState({});
  const { formatDate } = usePolicyCalcFunc();

  useEffect(() => {
    const keyObj = {};
    inputFields.forEach((field) => {
      keyObj[field.id] = "";
    });
    keyObj["status"] = "";
    setFormData(keyObj);
  }, [inputFields]);

  useEffect(() => {
    if (updateData && Object.keys(updateData).length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        ...updateData,
        // Parse the dates using moment to display them correctly
        dob: updateData.dob
          ? moment(updateData.dob, "DD-MM-YYYY").toDate()
          : null,
        doa: updateData.doa
          ? moment(updateData.doa, "DD-MM-YYYY").toDate()
          : null,
      }));
    }
  }, [updateData]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    // Update form data
    setFormData((prevData) => ({ ...prevData, [id]: value }));

    // Validate email and phone fields
    if (id === "email" && value && !validateEmail(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    if (id === "phoneNumber" && value && !validatePhone(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Invalid phone number",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "",
      }));
    }
  };

  const handleDateChange = (date, id) => {
    // const newDate = formatDate(date);
    setFormData((prevData) => ({ ...prevData, [id]: date }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;

    // Check if fields (except name) are filled and validate
    Object.keys(formData).forEach((key) => {
      if (key !== "username" && key !== "status" && formData[key]) {
        if (key === "email" && !validateEmail(formData[key])) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Invalid email format",
          }));
          valid = false;
        } else if (key === "phoneNumber" && !validatePhone(formData[key])) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phoneNumber: "Invalid phone number",
          }));
          valid = false;
        }
      }
    });

    // If any validation failed, show toast error
    if (!valid) {
      toast.error("Please fill out all fields correctly.");
      return;
    }

    // Format dates before saving
    const formattedData = {
      ...formData,
      dob: formData.dob ? moment(formData.dob).format("DD-MM-YYYY") : "",
      doa: formData.doa ? moment(formData.doa).format("DD-MM-YYYY") : "",
    };
    // If all validations passed, save form data
    handleSaveFormData(formattedData);
  };

  return (
    <>
      <div className="container my-2">
        {inputFields && (
          <form className="row g-4" onSubmit={handleSubmit}>
            {inputFields.map((inputs, index) => (
              <div className="col-md-6 d-flex flex-column" key={index}>
                <label htmlFor={inputs.id} className="form-label">
                  {inputs.label}
                  {inputs.required && <span className="text-danger">*</span>}
                </label>
                {inputs.type === "date" ? (
                  <DatePicker
                    className="form-control"
                    id={inputs.id}
                    selected={formData[inputs.id]}
                    onChange={(date) => handleDateChange(date, inputs.id)}
                    showMonthDropdown
                    showYearDropdown
                    showIcon
                    icon={<MdCalendarMonth className="text-primary fs-5" />}
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy" // Specify your date format here
                    placeholderText="DD-MM-YYYY"
                    required={inputs.required}
                  />
                ) : (
                  <input
                    type={inputs.type}
                    className="form-control"
                    id={inputs.id}
                    value={formData[inputs.id] || ""}
                    onChange={handleChange}
                    required={inputs.required}
                    maxLength={inputs.maxLength}
                  />
                )}

                {inputs.id === "email" && errors.email && (
                  <div className="text-danger">{errors.email}</div>
                )}
                {inputs.id === "phoneNumber" && errors.phoneNumber && (
                  <div className="text-danger">{errors.phoneNumber}</div>
                )}
              </div>
            ))}
            <div className="col-md-6 d-flex">
              <label htmlFor="status" className="form-label">
                Status
                <span className="text-danger">*</span>
              </label>
              <div className="form-check form-switch mx-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="status"
                  onChange={() => setSwitchStatus((prevCheck) => !prevCheck)}
                  value={
                    switchStatus
                      ? (formData.status = "Active")
                      : (formData.status = "Inactive")
                  }
                  checked={!!switchStatus}
                  style={{ cursor: "pointer" }}
                />
                <label className="form-check-label" htmlFor="status">
                  Active
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" type="submit">
                {_.isEmpty(updateData) ? "Save" : "Update"}
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default MasterForm;
