import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

// redux types

import { SIGN_IN, SIGN_UP, SIGN_OUT, RESET } from "./auth.type";

export const signIn = (userData) => async (dispatch) => {
  try {
    const User = await axios({
      method: "POST",
      url: `${BASE_URL}/auth/signin`,
      data: { credentials: userData },
    });
    localStorage.setItem(
      "bimabook",
      JSON.stringify({ token: User.data.token })
    );

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${User.data.token}`;
    return dispatch({ type: SIGN_IN, payload: User.data });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const signUp = (userData) => async (dispatch) => {
  try {
    const User = await axios({
      method: "POST",
      url: `${BASE_URL}/auth/signup`,
      data: { credentials: userData },
    });
    localStorage.setItem(
      "bimabook",
      JSON.stringify({ token: User.data.token })
    );

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${User.data.token}`;
    return dispatch({ type: SIGN_UP, payload: User.data });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const signOut = () => async (dispatch) => {
  try {
    localStorage.removeItem("bimabook");

    return dispatch({ type: SIGN_OUT, payload: {} });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const resetPwd = (userData) => async (dispatch) => {
  try {
    const User = await axios({
      method: "POST",
      url: `${BASE_URL}/auth/reset`,
      data: { credentials: userData },
    });

    return dispatch({ type: RESET, payload: User.data });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const deleteData = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/ledgers/delete`, {
      params: params,
    });

    return dispatch({
      type: "GET_CONFIRMATION",
      payload: response.data.exists,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
