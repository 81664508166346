import React from "react";

const Remarks = ({ formData, handleChange }) => {
  return (
    <>
      <div className="row  g-3">
        <h5 className="text-primary">Remarks:</h5>
        <div className="col">
          <label htmlFor="remarks" className="form-label">
            Remarks
          </label>
          <textarea
            rows={2}
            className="form-control "
            id="remarks"
            name="remarks"
            value={formData.remarks}
            onChange={handleChange}
            placeholder="Your remarks here..."
          />
        </div>
      </div>
    </>
  );
};

export default Remarks;
