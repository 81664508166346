import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

export const getSP = () => async (dispatch) => {
  try {
    const serviceProvider = await axios({
      method: "GET",
      url: `${BASE_URL}/serviceProviders`,
    });

    return dispatch({
      type: "GET_SERVICE_PROVIDER",
      payload: serviceProvider.data.spData,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const addNewSP = (formData) => async (dispatch) => {
  try {
    const newSP = await axios({
      method: "POST",
      url: `${BASE_URL}/serviceProviders/new`,
      data: { spData: formData },
    });
    return dispatch({
      type: "ADD_SERVICE_PROVIDER",
      payload: newSP.data,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const deleteSp = (_id) => async (dispatch) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${BASE_URL}/serviceProviders/delete/${_id}`,
    });

    return dispatch({
      type: "DELETE_SERVICE_PROVIDER",
      payload: response.data.message,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const updateSp = (updatedData) => async (dispatch) => {
  try {
    const id = updatedData._id;
    const updateSp = await axios({
      method: "PUT",
      url: `${BASE_URL}/serviceProviders/update/${id}`,
      data: { spData: updatedData },
    });
    return dispatch({
      type: "UPDATE_SERVICE_PROVIDER",
      payload: updateSp.data,
    });
  } catch (error) {
    return dispatch({
      type: "ERROR",
      payload: error,
    });
  }
};
