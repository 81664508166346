import React, { useEffect, useState } from "react";
import ReactDataTable from "../ReactDataTable";
import LedgerData from "../LedgerData";
import MasterListing from "./MasterListing";
import { useAction } from "../../helperFunctions/useAction";

const MasterLedger = ({
  collectionColumns,
  ledgerColumns,
  policyArray,
  collectionArray,
  deleteFunction,
  getFunction,
  addFunction,
  basedOn,
  updateFunction,
  title,
  masterData,
}) => {
  const [collection, setCollection] = useState([]);
  const handleAddAction = useAction(addFunction);
  const handleGetAction = useAction(getFunction);

  useEffect(() => {
    if (collectionArray && collectionArray.length > 0) {
      let newCollection = [];
      collectionArray.forEach((item) => {
        if (item[basedOn]) {
          newCollection.push(item);
        }
      });
      setCollection(newCollection);
    }
  }, [collectionArray]);

  const handleFormSubmit = async (formData) => {
    await handleAddAction(formData);
    handleGetAction();
  };

  return (
    <>
      <div className="m-3">
        <div className="card p-2 m-1 shadow-sm  align-items-center">
          <h3 className="text-primary fs-3 fw-bold">{title}</h3>
        </div>
        {/**
        <div className="card p-2 m-1 shadow-sm">
          <h5>Policy List</h5>
          <ReactDataTable array={policyArray} columnsToShow={ledgerColumns} />
        </div>
 */}
        <div className="card p-2 m-1 shadow-sm">
          <h5>Ledger</h5>
          <LedgerData
            array={policyArray}
            basedOn={basedOn}
            handleFormSubmit={handleFormSubmit}
            collectionList={collectionArray}
            masterData={masterData}
          />
        </div>
        <div className="card p-2 m-1 shadow-sm">
          <h5>{basedOn === "agentName" ? "Paying List" : "Collection List"}</h5>

          <MasterListing
            dataTableArray={collection}
            deleteFunction={deleteFunction}
            columnsToShow={collectionColumns}
            getFunction={getFunction}
            updateFunction={updateFunction}
          />
        </div>
      </div>
    </>
  );
};

export default MasterLedger;
