import { IoIosAddCircleOutline } from "react-icons/io";
// export const API_URL = window.location.hostname.includes("localhost")
//   ? "http://localhost"
//   : "";

// export const PORT = 8000;

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GST = 18;
export const policyColumn = [
  { title: "Policy Number", data: "policyNo" },
  { title: "Registration Number", data: "registrationNo" },
  { title: "Insurance Company", data: "insuranceCompany" },
  { title: "Service Provider", data: "serviceProviderName" },
  { title: "Agent", data: "agentName" },
  { title: "Customer/Agent", data: "customerName" },
  { title: "Insured", data: "insuredName" },
  { title: "Insurance Type", data: "insuranceType" },
  { title: "Vehicle Type", data: "vehicleType" },
  { title: "Payment Type", data: "paymentType" },
  { title: "Policy Issue Date", data: "issueDate" },
  { title: "Policy Start Date", data: "startDate" },
  { title: "Policy Expiry Date", data: "expiryDate" },
  { title: "Own Damage (OD)", data: "od" },
  { title: "Third Party (TP)", data: "tp" },
  { title: "Net Premium", data: "netPremium" },
  { title: "GST Amount", data: "gst" },
  { title: "Gross Premium", data: "grossPremium" },
  { title: "OD Reward %age (Self))", data: "odSelfCommissionRate" },
  { title: "TP Reward %age (Self)", data: "tpSelfCommissionRate" },
  { title: "Reward Amount (Self)", data: "selfCommissionAmt" },
  { title: "OD Reward %age (Agent)", data: "odAgentCommissionRate" },
  { title: "TP Reward %age (Agent)", data: "tpAgentCommissionRate" },
  { title: "Reward Amount (Agent)", data: "agentCommissionAmt" },
  { title: "Paid By Customer", data: "paidByCustomer" },
  { title: "My Contribution", data: "myContribution" },
  { title: "Balance", data: "balance" },
  { title: "Remarks", data: "remarks" },
];
export const policyColumnFilter = [
  "customerName",
  "insuranceCompany",
  "insuranceType",
];
export const customerColumn = [
  { title: "Customer", data: "customerName" },
  { title: "Email Address", data: "emailId" },
  { title: "Phone Number", data: "phoneNumber" },
  { title: "City", data: "city" },
  { title: "Date Of Birth", data: "dob" },
  { title: "Date Of Anniversary", data: "doa" },
  { title: "Status", data: "status" },
];
export const agentColumn = [
  { title: "Agent", data: "agentName" },
  { title: "Email Address", data: "emailId" },
  { title: "Phone Number", data: "phoneNumber" },
  { title: "City", data: "city" },
  { title: "Date Of Birth", data: "dob" },
  { title: "Date Of Anniversary", data: "doa" },
  { title: "Status", data: "status" },
];
export const spColumn = [
  { title: "Service Provider", data: "serviceProviderName" },
  { title: "Email Address", data: "emailId" },
  { title: "Phone Number", data: "phoneNumber" },
  { title: "City", data: "city" },
  { title: "Date Of Birth", data: "dob" },
  { title: "Date Of Anniversary", data: "doa" },
  { title: "Status", data: "status" },
];
export const companyLedgerColumns = [
  { title: "Policy Number", data: "policyNo" },
  { title: "Insurance Company", data: "insuranceCompany" },
  { title: "Policy Issue Date", data: "issueDate" },
  { title: "Own Damage (OD)", data: "od" },
  { title: "Third Party (TP)", data: "tp" },
  { title: "Net Premium", data: "netPremium" },
  { title: "GST Amount", data: "gst" },
  { title: "Gross Premium", data: "grossPremium" },
  { title: "OD Reward %age (Self))", data: "odSelfCommissionRate" },
  { title: "TP Reward %age (Self)", data: "tpSelfCommissionRate" },
  { title: "Reward %age (Self)", data: "selfCommissionRate" },
  { title: "Reward Amount (Self)", data: "selfCommissionAmt" },
];
export const spLedgerColumns = [
  { title: "Policy Number", data: "policyNo" },
  { title: "Service Provider", data: "serviceProviderName" },
  { title: "Policy Issue Date", data: "issueDate" },
  { title: "Own Damage (OD)", data: "od" },
  { title: "Third Party (TP)", data: "tp" },
  { title: "Net Premium", data: "netPremium" },
  { title: "GST Amount", data: "gst" },
  { title: "Gross Premium", data: "grossPremium" },
  { title: "OD Reward %age (Self))", data: "odSelfCommissionRate" },
  { title: "TP Reward %age (Self)", data: "tpSelfCommissionRate" },
  { title: "Reward %age (Self)", data: "selfCommissionRate" },
  { title: "Reward Amount (Self)", data: "selfCommissionAmt" },
];
export const customerLedgerColumns = [
  { title: "Policy Number", data: "policyNo" },
  { title: "Customer", data: "customerName" },
  { title: "Policy Issue Date", data: "issueDate" },
  { title: "Own Damage (OD)", data: "od" },
  { title: "Third Party (TP)", data: "tp" },
  { title: "Net Premium", data: "netPremium" },
  { title: "GST Amount", data: "gst" },
  { title: "Gross Premium", data: "grossPremium" },
  { title: "OD Reward %age (Self))", data: "odSelfCommissionRate" },
  { title: "TP Reward %age (Self)", data: "tpSelfCommissionRate" },
  { title: "Reward Amount (Self)", data: "selfCommissionAmt" },
  { title: "Paid By Customer", data: "paidByCustomer" },
  { title: "My Contribution", data: "myContribution" },
  { title: "Balance", data: "balance" },
];
export const agentLedgerColumns = [
  { title: "Policy Number", data: "policyNo" },
  { title: "Agent", data: "agentName" },
  { title: "Policy Issue Date", data: "issueDate" },
  { title: "Own Damage (OD)", data: "od" },
  { title: "Third Party (TP)", data: "tp" },
  { title: "Net Premium", data: "netPremium" },
  { title: "GST Amount", data: "gst" },
  { title: "Gross Premium", data: "grossPremium" },
  { title: "OD Reward %age (Agent)", data: "odAgentCommissionRate" },
  { title: "TP Reward %age (Agent)", data: "tpAgentCommissionRate" },
  { title: "Reward Amount (Agent)", data: "agentCommissionAmt" },
  { title: "Paid By Customer", data: "paidByCustomer" },
  { title: "My Contribution", data: "myContribution" },
  { title: "Balance", data: "balance" },
];
export const spCollectionColumn = [
  { title: "Service Provider", data: "serviceProviderName" },
  { title: "To Bank", data: "toBank" },
  { title: "Basic Amount", data: "basicAmount" },
  { title: "TDS %age", data: "tds" },
  { title: "Amount After TDS", data: "afterTds" },
  { title: "Collection Date", data: "dateOfCollection" },
  { title: "Collected For Month", data: "collectedForMonth" },
];
export const agentCollectionColumn = [
  { title: "Agent", data: "agentName" },
  { title: "To Bank", data: "toBank" },
  { title: "Basic Amount", data: "basicAmount" },
  { title: "TDS %age", data: "tds" },
  { title: "Amount After TDS", data: "afterTds" },
  { title: "Collection Date", data: "dateOfCollection" },
  { title: "Collected For Month", data: "collectedForMonth" },
];
export const customerCollectionColumn = [
  { title: "Customer", data: "customerName" },
  { title: "To Bank", data: "toBank" },
  { title: "Basic Amount", data: "basicAmount" },
  { title: "TDS %age", data: "tds" },
  { title: "Amount After TDS", data: "afterTds" },
  { title: "Collection Date", data: "dateOfCollection" },
  { title: "Collected For Month", data: "collectedForMonth" },
];
export const companyCollectionColumn = [
  { title: "Insurance Company", data: "insuranceCompany" },
  { title: "To Bank", data: "toBank" },
  { title: "Basic Amount", data: "basicAmount" },
  { title: "TDS %age", data: "tds" },
  { title: "Amount After TDS", data: "afterTds" },
  { title: "Collection Date", data: "dateOfCollection" },
  { title: "Collected For Month", data: "collectedForMonth" },
];
export const customerFormInputFields = [
  {
    label: "Customer Name",
    id: "customerName",
    type: "text",
    required: true,
  },
  {
    label: "Phone Number",
    id: "phoneNumber",
    type: "tel",
    required: false,
    maxLength: 10,
  },
  {
    label: "Email Id",
    id: "emailId",
    type: "email",
    required: false,
  },
  {
    label: "City",
    id: "city",
    type: "text",
    required: false,
  },
  {
    label: "Date of Birth",
    id: "dob",
    type: "date",
    required: false,
  },
  {
    label: "Date of Anniversary ",
    id: "doa",
    type: "date",
    required: false,
  },
];
export const agentFormInputFields = [
  {
    label: "Agent Name",
    id: "agentName",
    type: "text",
    required: true,
  },
  {
    label: "Phone Number",
    id: "phoneNumber",
    type: "tel",
    required: false,
    maxLength: 10,
  },
  {
    label: "Email Id",
    id: "emailId",
    type: "email",
    required: false,
  },
  {
    label: "City",
    id: "city",
    type: "text",
    required: false,
  },
  {
    label: "Date of Birth",
    id: "dob",
    type: "date",
    required: false,
  },
  {
    label: "Date of Anniversary ",
    id: "doa",
    type: "date",
    required: false,
  },
];
export const spFormInputFields = [
  {
    label: "Service Provider Name",
    id: "serviceProviderName",
    type: "text",
    required: true,
  },
  {
    label: "Phone Number",
    id: "phoneNumber",
    type: "tel",
    required: false,
    maxLength: 10,
  },
  {
    label: "Email Id",
    id: "emailId",
    type: "email",
    required: false,
  },
  {
    label: "City",
    id: "city",
    type: "text",
    required: false,
  },
  {
    label: "Date of Birth",
    id: "dob",
    type: "date",
    required: false,
  },
  {
    label: "Date of Anniversary ",
    id: "doa",
    type: "date",
    required: false,
  },
];
export const modalButtonsConfig = [
  {
    label: "Add New",
  },
];
export const modalIconConfig = [
  {
    label: "Add New",
    icon: IoIosAddCircleOutline,
    className: "ms-2 fs-3 text-primary rounded-circle",
  },
];
export const formDataEntriesAgentFalse = {
  agentName: "Self",
  agentId: "",
  selfCommissionRate: 0,
  agentCommissionRate: 0,
  agentCommissionAmt: 0,
  odAgentCommissionRate: 0,
  tpAgentCommissionRate: 0,
};
export const formDataEntriesBothFalse = {
  agentName: "Self",
  agentId: "",
  registrationNo: "",
  vehicleType: "",
  od: 0,
  tp: 0,
  agentCommissionRate: 0,
  agentCommissionAmt: 0,
  odAgentCommissionRate: 0,
  tpAgentCommissionRate: 0,
};
export const formDataEntriesMotorFalse = {
  agentName: "Agent",
  customerId: "",
  registrationNo: "",
  vehicleType: "",
  od: 0,
  tp: 0,
};
export const formDataEntriesBothTrue = {
  agentName: "Agent",
  customerId: "",
  agentCommissionRate: 0,
  selfCommissionRate: 0,
};
