import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from "react-router-dom";
import { useAction } from "../helperFunctions/useAction";
import { getAgents } from "../redux/reducers/agents/agents.action";
import { getCustomers } from "../redux/reducers/customer/customer.action";
import { getSP } from "../redux/reducers/serviceProvider/sp.action";
import { useSelector } from "react-redux";
import { getLedger } from "../redux/reducers/ledger/ledger.action";
import selectFieldData from "../configurations/selectFieldData.json";

const IndividualLedger = ({ masterDetails }) => {
  const [selectedDateRange, setSelectedDateRange] = useState("all");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredValue, setFilteredValue] = useState("All");
  const [policies, setPolicies] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [ledgerType, setLedgerType] = useState("");

  const [searchParams] = useSearchParams();
  const handleGetAgents = useAction(getAgents);
  const handleGetCustomers = useAction(getCustomers);
  const handleGetSp = useAction(getSP);
  const handleGetLedger = useAction(getLedger);

  const id = searchParams.get("id");
  const basedOn = searchParams.get("basedOn");

  const agentsList = useSelector((state) => state.agentsReducer.agents);
  const spList = useSelector((state) => state.spReducer.serviceProviders);
  const customersList = useSelector((state) => state.customerReducer.customers);
  const ledgersList = useSelector((state) => state.ledgerReducer.ledgers);

  useEffect(() => {
    handleGetAgents();
    handleGetCustomers();
    handleGetSp();
  }, [handleGetAgents, handleGetCustomers, handleGetSp]);

  useEffect(() => {
    switch (basedOn) {
      case "agentName":
        setLedgerType("agentId");
        setMasterData(agentsList);
        break;
      case "customerName":
        setLedgerType("customerId");
        setMasterData(customersList);
        break;
      case "serviceProviderName":
        setLedgerType("serviceProviderId");
        setMasterData(spList);
        break;
      case "insuranceCompany":
        setLedgerType("insuranceCompanyId");
        setMasterData(selectFieldData[1].insurance_company);
        break;
      default:
        break;
    }
  }, [basedOn, agentsList, customersList, spList]);

  useEffect(() => {
    if (id) {
      setFilteredValue(id);
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        !filteredValue ||
        !selectedDateRange ||
        !startDate ||
        !endDate ||
        !ledgerType ||
        !basedOn
      ) {
        // If any required state value is not set, return early or handle appropriately
        return;
      }

      let params = {
        [ledgerType]: filteredValue === "All" ? basedOn : filteredValue,
      };

      const formatDate = (date) => {
        const d = new Date(date);
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
      };

      switch (selectedDateRange) {
        case "all":
          params.openingBalanceDate = formatDate(
            new Date(new Date().getFullYear() - 1, 2, 31)
          );
          break;
        case "thisMonth":
          params.startDate = formatDate(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          );
          params.endDate = formatDate(
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          );
          params.openingBalanceDate = formatDate(
            new Date(new Date().getFullYear(), new Date().getMonth(), 0)
          );
          break;
        case "lastMonth":
          params.startDate = formatDate(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          );
          params.endDate = formatDate(
            new Date(new Date().getFullYear(), new Date().getMonth(), 0)
          );
          params.openingBalanceDate = formatDate(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0)
          );
          break;
        case "currentYear":
          params.startDate = formatDate(
            new Date(new Date().getFullYear(), 3, 1)
          );
          params.endDate = formatDate(
            new Date(new Date().getFullYear() + 1, 2, 31)
          );
          params.openingBalanceDate = formatDate(
            new Date(new Date().getFullYear(), 2, 31)
          );
          break;
        case "custom":
          params.openingBalanceDate = formatDate(startDate);
          params.startDate = formatDate(startDate);
          params.endDate = formatDate(endDate);
          break;
        default:
          break;
      }

      handleGetLedger(params);
    };

    fetchData();
  }, [
    filteredValue,
    selectedDateRange,
    startDate,
    endDate,
    ledgerType,
    basedOn,
    handleGetLedger,
  ]);

  useEffect(() => {
    setPolicies(ledgersList);
  }, [ledgersList]);

  const handleDateRangeChange = (range) => {
    setSelectedDateRange(range);
    if (range !== "custom") {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };

  const findSelectedOption = (options, value) =>
    options.find((option) => option.value === value) || null;

  return (
    <>
      <div className="card p-2 m-1 shadow-sm  align-items-center">
        <span className="text-primary fs-3 fw-bold">Monthly Ledger</span>
      </div>
      <div className="card p-2 m-1 shadow-sm">
        <div className="row">
          <div className="row mb-4 col-md-7">
            <div className="col-md-4">
              <label>Date Range:</label>
              <select
                className="form-select"
                value={selectedDateRange}
                onChange={(e) => handleDateRangeChange(e.target.value)}
              >
                <option value="all">All</option>
                <option value="thisMonth">This Month</option>
                <option value="lastMonth">Last Month</option>
                <option value="currentYear">Current Year (Fiscal Year)</option>
                <option value="custom">Custom</option>
              </select>
            </div>
            {selectedDateRange === "custom" && (
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6 d-flex flex-column">
                    <label className="mb-1">Start Date:</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showIcon
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-column">
                    <label className="mb-1">End Date:</label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <label>Filter By Name:</label>
            {masterData && (
              <Select
                className="w-100"
                value={findSelectedOption(
                  [
                    { value: "All", label: "All" },
                    ...masterData.map((master) => ({
                      value: master._id,
                      label: master[basedOn],
                    })),
                  ],
                  filteredValue
                )}
                defaultValue={findSelectedOption(
                  [
                    { value: "All", label: "All" },
                    ...masterData.map((master) => ({
                      value: master._id,
                      label: master[basedOn],
                    })),
                  ],
                  id || "All"
                )}
                onChange={(selectedOption) =>
                  setFilteredValue(selectedOption ? selectedOption.value : "")
                }
                options={[
                  { value: "All", label: "All" },
                  ...masterData.map((master) => ({
                    value: master._id,
                    label: master[basedOn],
                  })),
                ]}
                isClearable
                placeholder="Choose..."
                required
              />
            )}
          </div>
        </div>
        <div>
          <table className="table table-hover table-striped mt-3">
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Months</th>
                <th>Agent</th>
                <th>Number of Policies</th>
                <th>Gross Premium</th>
                {basedOn !== "serviceProviderName" ? (
                  <th>Paid By Customer</th>
                ) : null}
                {basedOn !== "serviceProviderName" ? (
                  <th>My Contribution</th>
                ) : null}
                <th>Reward Amount</th>
                {basedOn !== "serviceProviderName" ? <th>Balance</th> : null}
                <th>Carry Forword Amount</th>
                <th>Total Collected</th>
                <th>Final Balance</th>
              </tr>
            </thead>
            <tbody>
              {policies &&
                policies.map((policy, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{policy.monthYear}</td>
                    <td>{policy.name}</td>
                    <td>{policy.noOfPolicies}</td>
                    <td>{policy.totalGrossPremium.toFixed()}</td>
                    {basedOn !== "serviceProviderName" && (
                      <td>{policy.totalPaidByCustomer.toFixed()}</td>
                    )}
                    {basedOn !== "serviceProviderName" && (
                      <td>{policy.totalMyContribution.toFixed()}</td>
                    )}
                    <td>{policy.totalRewardAmount.toFixed()}</td>
                    {basedOn !== "serviceProviderName" && (
                      <td>{policy.totalCommission.toFixed()}</td>
                    )}
                    <td>{policy.openingBalance.toFixed()}</td>
                    <td>{policy.totalCollected.toFixed()}</td>
                    <td>{policy.balance.toFixed()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default IndividualLedger;
