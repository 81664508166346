import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactDataTable from "../ReactDataTable";
import { policyColumn } from "../../configurations/config";
import FilterComponent from "../filter/FilterComponent";
import { policyColumnFilter } from "../../configurations/config";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { useAction } from "../../helperFunctions/useAction";
import { confirmDialog } from "primereact/confirmdialog";

const PolicyList = ({ getFunction, deleteFunction, policyData }) => {
  const [filteredArray, setFilteredArray] = useState([]);
  const navigate = useNavigate();
  const handleGetAction = useAction(getFunction);
  const handleDeleteAction = useAction(deleteFunction);

  const handleUpdatedArray = (updatedArray) => {
    setFilteredArray(updatedArray);
  };
  const accept = async (id) => {
    await handleDeleteAction(id);
    handleGetAction();
  };

  const confirmDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to Delete this Policy?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => accept(id),
    });
  };

  const handleDelete = async (rowData) => {
    const id = rowData._id;
    await confirmDelete(id);
  };

  const handleEdit = (rowData) => {
    const id = rowData._id;
    navigate(`/dashboard/policyUpdate/${id}`);
  };
  const dataTableButtonsConfig = [
    {
      label: "Edit",
      icon: CiEdit,
      className: " text-primary fs-4 ",
      onClick: (rowData) => handleEdit(rowData),
    },
    {
      label: "Delete",
      icon: MdDeleteOutline,
      className: "text-danger fs-4 ",
      onClick: (rowData) => handleDelete(rowData),
    },
  ];
  return (
    <>
      <div className="card p-2 m-3 shadow-sm  align-items-center">
        <h3 className="text-primary fs-3 fw-bold">Policy List</h3>
      </div>
      <div className="m-3 container_width bg-white rounded p-2">
        <FilterComponent
          array={policyData}
          dateField={"issueDate"}
          onFilteredArrayUpdate={handleUpdatedArray}
        />
        <ReactDataTable
          array={filteredArray}
          columnsToShow={policyColumn}
          filterableColumns={policyColumnFilter}
          dataTableButtonsConfig={dataTableButtonsConfig}
          includeCurrentPolicy={true}
        />
      </div>
    </>
  );
};

export default PolicyList;
