import React, { useRef } from "react";
import ReactDataTable from "../ReactDataTable";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import AddMaster from "./AddMaster";
import { useAction } from "../../helperFunctions/useAction";
import { confirmDialog } from "primereact/confirmdialog";
import { deleteData } from "../../redux/reducers/auth/auth.action";
import { toast } from "react-toastify";

const MasterListing = ({
  dataTableArray,
  columnsToShow,
  modalButtonsConfig,
  inputFields,
  getFunction,
  addFunction,
  deleteFunction,
  updateFunction,
  title,
  basedOn,
}) => {
  const childref = useRef();
  const handleDeleteAction = useAction(deleteFunction);
  const handleCheckDeletion = useAction(deleteData);

  const accept = async (id) => {
    try {
      const params = {
        id: id,
        basedOn: basedOn, // Ensure 'basedOn' is defined and has the correct value in this scope
      };
      const result = await handleCheckDeletion(params);

      if (!result.payload) {
        await handleDeleteAction(id);
        handleGetAction();
      } else {
        toast.warn(
          "Policy Exist with this Data, You are not allowed to delete."
        );
      }
    } catch (error) {
      console.error("Error during deletion process:", error);
    }
  };

  const confirmDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to Delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => accept(id),
    });
  };
  const handleGetAction = useAction(getFunction);
  const handleDelete = async (rowData) => {
    const id = rowData._id;
    await confirmDelete(id);
  };

  const dataTableButtonsConfig = [
    {
      label: "Delete",
      icon: MdDeleteOutline,
      className: "text-danger fs-4 ",
      onClick: (rowData) => handleDelete(rowData),
    },
    {
      label: "Edit",
      icon: CiEdit,
      className: " text-primary fs-4 ",
      onClick: (rowData) => childref.current.handleEdit(rowData),
    },
  ];
  return (
    <>
      <div className="m-3 bg-white p-2 rounded">
        <div className="d-flex justify-content-between">
          <h3 className="text-primary">{title}</h3>
          {modalButtonsConfig ? (
            <AddMaster
              buttonsConfig={modalButtonsConfig}
              inputFields={inputFields}
              getFunction={getFunction}
              addFunction={addFunction}
              updateFunction={updateFunction}
              basedOn={basedOn}
              ref={childref}
            />
          ) : (
            <AddMaster
              inputFields={inputFields}
              getFunction={getFunction}
              addFunction={addFunction}
              updateFunction={updateFunction}
              basedOn={basedOn}
              ref={childref}
            />
          )}
        </div>
        <div>
          <ReactDataTable
            array={dataTableArray}
            columnsToShow={columnsToShow}
            dataTableButtonsConfig={dataTableButtonsConfig}
          />
        </div>
      </div>
    </>
  );
};

export default MasterListing;
