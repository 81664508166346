import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FilterComponent = ({ array, dateField, onFilteredArrayUpdate }) => {
  const [selectedDateRange, setSelectedDateRange] = useState("all");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const filterArray = () => {
      if (Array.isArray(array) && dateField) {
        const parseDate = (dateString) => {
          const [day, month, year] = dateString.split("-");
          return new Date(year, month - 1, day);
        };

        const today = new Date();
        const filteredArray = [];

        array.forEach((item) => {
          const issueDateString = item.issueDate; // Assuming issueDate is the key
          const issueDateObj = parseDate(issueDateString);

          switch (selectedDateRange) {
            case "all":
              filteredArray.push(item);
              break;
            case "today":
              if (
                issueDateObj.getDate() === today.getDate() &&
                issueDateObj.getMonth() === today.getMonth() &&
                issueDateObj.getFullYear() === today.getFullYear()
              ) {
                filteredArray.push(item);
              }
              break;
            case "yesterday":
              const yesterday = new Date(today);
              yesterday.setDate(today.getDate() - 1);
              if (
                issueDateObj.getDate() === yesterday.getDate() &&
                issueDateObj.getMonth() === yesterday.getMonth() &&
                issueDateObj.getFullYear() === yesterday.getFullYear()
              ) {
                filteredArray.push(item);
              }
              break;
            case "thisMonth":
              if (
                issueDateObj.getMonth() === today.getMonth() &&
                issueDateObj.getFullYear() === today.getFullYear()
              ) {
                filteredArray.push(item);
              }
              break;
            case "lastMonth":
              const lastMonth = new Date(today);
              lastMonth.setMonth(today.getMonth() - 1);
              if (
                issueDateObj.getMonth() === lastMonth.getMonth() &&
                issueDateObj.getFullYear() === lastMonth.getFullYear()
              ) {
                filteredArray.push(item);
              }
              break;
            case "currentYear":
              if (issueDateObj.getFullYear() === today.getFullYear()) {
                filteredArray.push(item);
              }
              break;
            case "custom":
              if (issueDateObj >= startDate && issueDateObj <= endDate) {
                filteredArray.push(item);
              }
              break;
            default:
              break;
          }
        });

        onFilteredArrayUpdate(filteredArray);
      }
    };

    filterArray();
  }, [array, selectedDateRange, startDate, endDate]);

  const handleDateRangeChange = (range) => {
    setSelectedDateRange(range);
    // Reset custom date range if switching from "custom"
    if (range !== "custom") {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-4">
          <label>Date Range:</label>
          <select
            className="form-select"
            value={selectedDateRange}
            onChange={(e) => handleDateRangeChange(e.target.value)}
          >
            <option value="all">All</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="thisMonth">This Month</option>
            <option value="lastMonth">Last Month</option>
            <option value="currentYear">Current Year (Fiscal Year)</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        {selectedDateRange === "custom" && (
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 d-flex flex-column">
                <label className="mb-1">Start Date:</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-md-6 d-flex flex-column">
                <label className="mb-1">End Date:</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterComponent;
