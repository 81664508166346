import React from "react";
import Select from "react-select";
import selectFieldData from "../../configurations/selectFieldData.json";

const PolicyPremiums = ({
  formData,
  handleChange,
  isMotorInsurance,
  isAgent,
}) => {
  // Helper function to create options
  const createOptions = (data) =>
    data?.map((item) => ({
      value: item,
      label: item,
    })) || [];

  const paymentModeOptions = createOptions(selectFieldData[5]?.payment_mode);
  // Find the selected options for react-select fields
  const findSelectedOption = (options, value) => {
    return options.find((option) => option.value === value) || null;
  };
  return (
    <>
      <div className="row g-3">
        <h5 className="text-primary">Policy Premium</h5>
        {isMotorInsurance && (
          <div className="row g-2 col-md-8">
            <div className="col-md-6">
              <label htmlFor="od" className="form-label">
                Own Damage (OD)<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control form-control"
                id="od"
                name="od"
                value={formData.od}
                onChange={handleChange}
                required
                onWheel={(e) => e.target.blur()}
                disabled={!isMotorInsurance}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="tp" className="form-label">
                Third Party (TP)<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control form-control"
                id="tp"
                name="tp"
                value={formData.tp}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                required
                disabled={!isMotorInsurance}
              />
            </div>
          </div>
        )}
        <div className="col-md-4">
          <label htmlFor="netPremium" className="form-label">
            Net Premium{" "}
            {!isMotorInsurance && <span className="text-danger">*</span>}
          </label>
          <input
            type="number"
            min="0"
            step={0.01}
            className="form-control form-control"
            id="netPremium"
            name="netPremium"
            value={formData.netPremium}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            required
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="gst" className="form-label">
            GST Amount
            {isMotorInsurance && <span className="text-danger">*</span>}
          </label>
          <input
            type="number"
            min="0"
            step={0.01}
            className="form-control form-control"
            id="gst"
            name="gst"
            value={formData.gst}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            required={isMotorInsurance}
            readOnly={!isMotorInsurance}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="grossPremium" className="form-label">
            Gross Premium
          </label>
          <input
            type="number"
            min="0"
            step={0.01}
            className="form-control form-control"
            name="grossPremium"
            id="grossPremium"
            value={formData.grossPremium}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            required={isMotorInsurance}
            readOnly={!isMotorInsurance}
          />
        </div>
        {isMotorInsurance ? (
          <div className="row g-2 col-md-8">
            <div className="col-md-6">
              <label htmlFor="odSelfCommissionRate" className="form-label">
                OD Reward %age (Self)<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control form-control"
                id="odSelfCommissionRate"
                name="odSelfCommissionRate"
                value={formData.odSelfCommissionRate}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                required
                disabled={!isMotorInsurance}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="tpSelfCommissionRate" className="form-label">
                TP Reward %age (Self)<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                min="0"
                step={0.01}
                className="form-control form-control"
                name="tpSelfCommissionRate"
                id="tpSelfCommissionRate"
                value={formData.tpSelfCommissionRate}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                required
                disabled={!isMotorInsurance}
              />
            </div>
          </div>
        ) : (
          <div className="col-md-6">
            <label htmlFor="selfCommissionRate" className="form-label">
              Reward %age (Self)<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              min="0"
              step={0.01}
              className="form-control form-control"
              id="selfCommissionRate"
              name="selfCommissionRate"
              value={formData.selfCommissionRate}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              required
              disabled={isMotorInsurance}
            />
          </div>
        )}

        <div className={isMotorInsurance ? "col-md-4 " : "col-md-6"}>
          <label htmlFor="selfCommissionAmt" className="form-label">
            Reward Amount (Self)
          </label>
          <input
            type="number"
            min="0"
            step={0.01}
            className="form-control form-control"
            id="selfCommissionAmt"
            name="selfCommissionAmt"
            value={formData.selfCommissionAmt}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            required
            readOnly
          />
        </div>
        {isAgent ? (
          isMotorInsurance ? (
            <div className="row g-2 col-md-12">
              <div className="col-md-4">
                <label htmlFor="odAgentCommissionRate" className="form-label">
                  OD Reward %age (Agent)<span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control form-control"
                  id="odAgentCommissionRate"
                  name="odAgentCommissionRate"
                  value={formData.odAgentCommissionRate}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  required
                  disabled={!isMotorInsurance}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="tpAgentCommissionRate" className="form-label">
                  TP Reward %age (Agent)<span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control form-control"
                  name="tpAgentCommissionRate"
                  id="tpAgentCommissionRate"
                  value={formData.tpAgentCommissionRate}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  required
                  disabled={!isMotorInsurance}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="agentCommissionAmt" className="form-label">
                  Reward Amount (Agent)
                </label>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control form-control"
                  id="agentCommissionAmt"
                  name="agentCommissionAmt"
                  value={formData.agentCommissionAmt}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  required
                  disabled={!isAgent}
                  readOnly
                />
              </div>
            </div>
          ) : (
            <div className="row g-3 ">
              <div className="col-md-6">
                <label htmlFor="agentCommissionRate" className="form-label">
                  Reward %age (Agent)
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control form-control"
                  id="agentCommissionRate"
                  name="agentCommissionRate"
                  value={formData.agentCommissionRate}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  required
                  disabled={!isAgent}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="agentCommissionAmt" className="form-label">
                  Reward Amount (Agent)
                </label>
                <input
                  type="number"
                  min="0"
                  step={0.01}
                  className="form-control form-control"
                  id="agentCommissionAmt"
                  name="agentCommissionAmt"
                  value={formData.agentCommissionAmt}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  required
                  disabled={!isAgent}
                />
              </div>
            </div>
          )
        ) : null}

        <div className="col-md-12">
          <label htmlFor="paymentMode" className="form-label">
            Payment Mode<span className="text-danger">*</span>
          </label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            id="paymentMode"
            name="paymentMode"
            options={paymentModeOptions}
            value={findSelectedOption(paymentModeOptions, formData.paymentMode)}
            onChange={(selectedOption) =>
              handleChange({
                name: "paymentMode",
                value: selectedOption ? selectedOption.value : "",
              })
            }
            isClearable
            isSearchable
            placeholder="Select Payment Mode"
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="paidByCustomer" className="form-label">
            Paid By Customer<span className="text-danger">*</span>
          </label>
          <input
            type="number"
            min="0"
            step={0.01}
            className="form-control form-control"
            id="paidByCustomer"
            name="paidByCustomer"
            value={formData.paidByCustomer}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            required
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="myContribution" className="form-label">
            My Contribution<span className="text-danger">*</span>
          </label>
          <input
            type="number"
            min="0"
            step={0.01}
            className="form-control form-control"
            id="myContribution"
            name="myContribution"
            value={formData.myContribution}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            required
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="balance" className="form-label">
            Balance
          </label>
          <input
            type="number"
            className="form-control form-control"
            id="balance"
            name="balance"
            value={formData.balance}
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            required
            readOnly={true}
          />
        </div>
      </div>
    </>
  );
};

export default PolicyPremiums;
