import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

//redux types
import { GET_USER } from "./user.type";

export const getUser = (_id) => async (dispatch) => {
  try {
    const User = await axios({
      method: "GET",
      url: `${BASE_URL}/user/${_id}`,
    });
    return dispatch({ type: GET_USER, payload: User.data });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

// export const getMySelf = () => async (dispatch) => {
//   try {
//     const user = await axios({
//       method: "GET",
//       url: `${BASE_URL}/user/`,
//     });
//     return dispatch({ type: "SELF", payload: { ...user.data.user } });
//   } catch (error) {
//     return dispatch({ type: "ERROR", payload: error });
//   }
// };

export const getMySelf = () => async (dispatch) => {
  try {
    const user = await axios({
      method: "GET",
      url: `${BASE_URL}/user/`,
    });

    return dispatch({ type: "SELF", payload: { ...user.data.user } });
  } catch (error) {
    // Handle error user
    if (error.message && error.response.status === 401) {
      // If user not found, remove token from local storage
      localStorage.removeItem("bimabook");
    }
    return dispatch({
      type: "ERROR",
      payload: error || "An error occurred",
    });
  }
};

export const clearUser = () => async (dispatch) => {
  try {
    return dispatch({ type: "CLEAR_USER", payload: {} });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
