import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

export const addPolicy = (formdata) => async (dispatch) => {
  try {
    const policyData = await axios({
      method: "POST",
      url: `${BASE_URL}/formData/new`,
      data: formdata,
    });
    return dispatch({
      type: "ADD_POLICY_DATA",
      payload: policyData.data,
    });
  } catch (error) {
    return dispatch({ type: "error", payload: error });
  }
};

export const getPolicyData = () => async (dispatch) => {
  try {
    const policyData = await axios({
      method: "GET",
      url: `${BASE_URL}/formData/`,
    });
    return dispatch({
      type: "GET_POLICY_DATA",
      payload: policyData.data.tableData,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const deletePolicy = (_id) => async (dispatch) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${BASE_URL}/formData/delete/${_id}`,
    });

    return dispatch({
      type: "DELETE_POLICY_DATA",
      payload: response.data.message,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const updatePolicy = (updatedData) => async (dispatch) => {
  try {
    function extractIdFromFormData(updatedData) {
      let id = null;
      for (let entry of updatedData.entries()) {
        let [key, value] = entry;
        if (key === "_id") {
          id = value;
          break;
        }
      }
      return id;
    }
    const id = extractIdFromFormData(updatedData);
    const updatePolicy = await axios({
      method: "PUT",
      url: `${BASE_URL}/formData/update/${id}`,
      data: updatedData,
    });
    return dispatch({
      type: "UPDATE_POLICY_DATA",
      payload: updatePolicy.data,
    });
  } catch (error) {
    return dispatch({
      type: "ERROR",
      payload: error,
    });
  }
};

export const checkPolicy = (value) => async (dispatch) => {
  const params = {};
  console.log(value);
  if (value.policyNo) params.policyNo = value.policyNo;
  if (value.registrationNo) params.registrationNo = value.registrationNo;
  try {
    const policyData = await axios({
      method: "GET",
      url: `${BASE_URL}/formData/check`,
      params: { params },
    });
    return dispatch({
      type: "CHECK_POLICY",
      payload: policyData.data.exists,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
