import { GET_LEDGER, GET_YEARLY_LEDGER } from "./ledger.type";
const initialState = {
  ledgers: [],
  yearlyLedgers: [],
};

const ledgerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEDGER:
      return {
        ...state,
        ledgers: action.payload,
      };
    case GET_YEARLY_LEDGER:
      return {
        ...state,
        yearlyLedgers: action.payload,
      };

    default:
      return state;
  }
};

export default ledgerReducer;
