import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

export const getCollection = () => async (dispatch) => {
  try {
    const collection = await axios({
      method: "GET",
      url: `${BASE_URL}/collection/`,
    });
    return dispatch({
      type: "GET_COLLECTION_DATA",
      payload: collection.data.collectionData,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const addCollection = (formData) => async (dispatch) => {
  try {
    const collection = await axios({
      method: "POST",
      url: `${BASE_URL}/collection/new`,
      data: { collectionData: formData },
    });

    return dispatch({
      type: "ADD_COLLECTION_DATA",
      payload: collection.data,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const deleteCollection = (id) => async (dispatch) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${BASE_URL}/collection/delete/${id}`,
    });

    return dispatch({
      type: "DELETE_COLLECTION_DATA",
      payload: response.data.message,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};

export const updateCollection = (updatedData) => async (dispatch) => {
  try {
    const id = updatedData._id;
    const updateCollection = await axios({
      method: "PUT",
      url: `${BASE_URL}/collection/update/${id}`,
      data: { collectionData: updatedData },
    });
    return dispatch({
      type: "UPDATE_COLLECTION_DATA",
      payload: updateCollection.data,
    });
  } catch (error) {
    return dispatch({
      type: "ERROR",
      payload: error,
    });
  }
};
