import axios from "axios";
import { BASE_URL } from "../../../configurations/config";

export const getLedger = (params) => async (dispatch) => {
  try {
    const ledgers = await axios.get(`${BASE_URL}/ledgers/`, {
      params: params,
    });

    return dispatch({
      type: "GET_LEDGER",
      payload: ledgers.data,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
export const getYearlyLedger = (params) => async (dispatch) => {
  try {
    const ledgers = await axios.get(`${BASE_URL}/ledgers/ledger`, {
      params: params,
    });

    return dispatch({
      type: "GET_YEARLY_LEDGER",
      payload: ledgers.data,
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error });
  }
};
