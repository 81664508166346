import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import { FaGoogle, FaFacebookF } from "react-icons/fa";
import { signIn } from "../redux/reducers/auth/auth.action";
import { getMySelf } from "../redux/reducers/user/user.action";
import { useAction } from "../helperFunctions/useAction";
import { toast } from "react-toastify";

const SignIn = () => {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const navigate = useNavigate();
  const handleGetSelf = useAction(getMySelf);
  const handleSignIn = useAction(signIn);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUserData((prev) => ({ ...prev, [id]: value }));

    // Validate on change
    validateField(id, value);
  };

  const validateField = (id, value) => {
    let isValid = true;

    if (id === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      isValid = emailPattern.test(value);
    }

    setErrors((prev) => ({ ...prev, [id]: !isValid }));
  };

  const handleSubmit = async () => {
    const { email } = userData;
    const newErrors = {
      email: !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email),
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      toast.error("Please fill out all fields correctly.");
      return;
    }

    try {
      await handleSignIn(userData);
      await handleGetSelf();
      navigate("/dashboard");
      setUserData({ email: "", password: "" });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while signing in.");
      }
    }
  };

  return (
    <div className="register_bg">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-contain-center">
          <div className="col-xl-12">
            <div className="signin_card">
              <div className="signin_card_body">
                <div className="row m-0">
                  <div className="col-xl-6 col-md-6 sign text-center">
                    <div>
                      <div className="text-start m-5">
                        <div className="logo">
                          <span className="text-white fw-bold fs-1">
                            BimaBook
                          </span>
                        </div>
                      </div>
                      <img
                        src="/images/log.png"
                        alt="logo"
                        className="education-img"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 my-3">
                    <div className="text-white fs-6">
                      <h3>Sign in your account</h3>
                      <span className="text-white fs-6">
                        Welcome back! Login with your data that you entered
                        <br /> during registration
                      </span>
                      {/**
                      <div className="login-social">
                        <Link
                          to="#"
                          className="btn btn-primary font-w800 d-block my-4 py-3"
                        >
                          <i className="px-2">
                            <FaGoogle />
                          </i>
                          Login with Google
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary font-w800 d-block my-4 py-3"
                        >
                          <i className="px-2">
                            <FaFacebookF />
                          </i>
                          Login with Facebook
                        </Link>
                      </div>
                       */}
                      <form>
                        <div className="mb-4">
                          <label htmlFor="email" className="mb-1">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            id="email"
                            value={userData.email}
                            onChange={handleChange}
                            className={`form-control bg-transparent text-white py-3 ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            required
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              Please enter a valid email address.
                            </div>
                          )}
                        </div>
                        <div className="mb-4">
                          <label htmlFor="password" className="mb-1">
                            <strong>Password</strong>
                          </label>
                          <input
                            type="password"
                            id="password"
                            value={userData.password}
                            onChange={handleChange}
                            className={`form-control bg-transparent text-white py-3 `}
                            required
                            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
                          />
                        </div>
                        <div className="row d-flex justify-content-between mt-4 mb-2">
                          <div className="mb-4">
                            <Link to="#" className="text-white">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn btn-primary form-control btn-block py-3"
                            onClick={handleSubmit}
                          >
                            Sign Me In
                          </button>
                        </div>
                        <div className="text-center fs-6 mt-2">
                          <Link to="/signup" className="text-white">
                            If don't have account! Click here
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
