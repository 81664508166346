// Modal.js
import React from "react";
import MasterForm from "./MasterComponents/MasterForm";

const Modal = ({
  show,
  title,
  onClose,
  inputFields,
  handleSaveFormData,
  children,
  updateData,
}) => {
  return (
    show && (
      <div
        className="modal fade show"
        tabIndex="-1"
        style={{ display: "block" }}
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              {inputFields ? (
                <MasterForm
                  updateData={updateData}
                  inputFields={inputFields}
                  handleSaveFormData={handleSaveFormData}
                />
              ) : (
                children
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
