import {
  GET_COLLECTION_DATA,
  ADD_COLLECTION_DATA,
  DELETE_COLLECTION_DATA,
  UPDATE_COLLECTION_DATA,
} from "./collection.type";

const initailState = {
  collectionList: [],
};

const collectionReducer = (state = initailState, action) => {
  switch (action.type) {
    case ADD_COLLECTION_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case GET_COLLECTION_DATA:
      return {
        ...state,
        collectionList: action.payload,
      };
    case DELETE_COLLECTION_DATA:
      return {
        ...state,
      };
    case UPDATE_COLLECTION_DATA:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default collectionReducer;
