import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CardComponent = ({ ledgerArray, handleCollection, basedOn }) => {
  const [title, setTitle] = useState("Collected");
  const navigate = useNavigate();

  useEffect(() => {
    if (basedOn === "agentName") {
      setTitle("Paid");
    }
  }, []);
  const handleRedirect = (id, basedOn) => {
    navigate(`/dashboard/ledgers/search?id=${id}&basedOn=${basedOn}`);
  };

  return (
    <>
      <div className="row">
        {ledgerArray &&
          ledgerArray.map((policy, index) => (
            <div key={index} className="col-md-4 rounded">
              <div className="shadow border rounded m-2 p-3">
                <h2
                  className="text-danger text-center border-bottom mb-3"
                  role="button"
                  onClick={() => handleRedirect(policy.id, basedOn)}
                >
                  {policy.name}
                </h2>
                <div className="row border-bottom">
                  <div className="d-flex flex-column mb-3 col-md-6">
                    <span className="fw-bold">Number of Policies:</span>
                    <span className="text-muted fw-bold">
                      {policy.noOfPolicies}
                    </span>
                  </div>
                  <div className="d-flex flex-column mb-3 col-md-6">
                    <span className="fw-bold">Gross Premium:</span>
                    <span className="text-muted fw-bold">
                      {policy.totalGrossPremium.toFixed()}
                    </span>
                  </div>
                </div>
                {basedOn !== "serviceProviderName" && (
                  <div className="row border-bottom">
                    <div className="d-flex flex-column mb-3 col-md-6">
                      <span className="fw-bold">Paid By Customer:</span>
                      <span className="text-muted fw-bold">
                        {policy.totalPaidByCustomer.toFixed()}
                      </span>
                    </div>
                    <div className="d-flex flex-column mb-3 col-md-6">
                      <span className="fw-bold">My Contribution</span>
                      <span className="text-muted fw-bold">
                        {policy.totalMyContribution.toFixed()}
                      </span>
                    </div>
                  </div>
                )}

                <div className="row border-bottom">
                  <div className="d-flex flex-column mb-3 col-md-6">
                    <span className="fw-bold">
                      {basedOn !== "serviceProviderName" ? "Balance:" : null}
                    </span>
                    <span className="text-muted fw-bold">
                      {policy.totalCommission.toFixed()}
                    </span>
                  </div>
                  <div className="d-flex flex-column mb-3 col-md-6">
                    <span className="fw-bold">Carry Forward Amount</span>
                    <span className="text-muted fw-bold">
                      {policy.openingBalance.toFixed()}
                    </span>
                  </div>
                </div>

                <div className="row mt-3">
                  <div
                    className="d-flex flex-column mb-3 col-md-4"
                    role="button"
                  >
                    <div>
                      <span className="fw-bold">{title}:</span>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm mx-1"
                        onClick={() => handleCollection(policy.name, policy.id)}
                      >
                        Update
                      </button>
                    </div>
                    <span className="text-muted fw-bold">
                      {policy.totalCollected.toFixed()}
                    </span>
                  </div>
                  <div className="d-flex flex-column mb-3 col-md-4">
                    <span className="fw-bold">Reward Amount </span>
                    <span className="text-muted fw-bold">
                      {policy.totalRewardAmount.toFixed()}
                    </span>
                  </div>
                  <div className="d-flex flex-column mb-3 col-md-4">
                    <span className="fw-bold">Final Balance: </span>
                    <span className="text-muted fw-bold">
                      {policy.balance.toFixed()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default CardComponent;
