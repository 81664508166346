import { combineReducers } from "redux";
import auth from "./auth/auth.reducer";
import user from "./user/user.reducer";
import customerReducer from "./customer/customer.reducer";
import policyData from "./policy/policy.reducer";
import collectionReducer from "./collection/collection.reducer";
import spReducer from "./serviceProvider/sp.reducer";
import agentsReducer from "./agents/agents.reducer";
import ledgerReducer from "./ledger/ledger.reducer";

const rootReducer = combineReducers({
  auth,
  user,
  customerReducer,
  policyData,
  collectionReducer,
  spReducer,
  agentsReducer,
  ledgerReducer,
});

export default rootReducer;
