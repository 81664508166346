import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { MdCalendarMonth } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const PolicyDates = ({ formData, handleChange, expiryMinDate }) => {
  const handleDateChange = (date, name) => {
    const event = {
      target: {
        name: name,
        value: date,
      },
    };
    handleChange(event.target);
  };
  const today = new Date();
  return (
    <>
      <div className="row g-3">
        <h5 className="text-primary ">Policy Dates:</h5>
        <div className="col-md-3">
          <label htmlFor="issueDate" className="form-label">
            Policy Issue Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={formData.issueDate && formData.issueDate}
            onChange={(date) => handleDateChange(date, "issueDate")}
            dateFormat="dd-MM-yyyy"
            showMonthDropdown
            showYearDropdown
            showIcon
            icon={<MdCalendarMonth className="text-primary fs-5" />}
            dropdownMode="select"
            className="form-control"
            id="issueDate"
            maxDate={today}
            name="issueDate"
            required
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="startDate" className="form-label">
            Policy Start Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={formData.startDate && formData.startDate}
            onChange={(date) => handleDateChange(date, "startDate")}
            dateFormat="dd-MM-yyyy"
            showMonthDropdown
            showYearDropdown
            showIcon
            icon={<MdCalendarMonth className="text-primary fs-5" />}
            dropdownMode="select"
            className="form-control"
            id="startDate"
            name="startDate"
            required
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="expiryDate" className="form-label">
            Policy Expiry Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={formData.expiryDate && formData.expiryDate}
            onChange={(date) => handleDateChange(date, "expiryDate")}
            dateFormat="dd-MM-yyyy"
            showMonthDropdown
            showYearDropdown
            showIcon
            minDate={expiryMinDate}
            icon={<MdCalendarMonth className="text-primary fs-5" />}
            dropdownMode="select"
            className="form-control"
            id="expiryDate"
            name="expiryDate"
            required
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="duration" className="form-label">
            Duration (Years)
          </label>
          <input
            type="number"
            min="0"
            className="form-control form-control"
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            readOnly
            onWheel={(e) => e.target.blur()}
            required
          />
        </div>
      </div>
    </>
  );
};

export default PolicyDates;
