import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.js";
import UserProfile from "./UserProfile.jsx";
import { useSelector } from "react-redux";
import { getMySelf } from "../redux/reducers/user/user.action.js";
import { signOut } from "../redux/reducers/auth/auth.action.js";
import { useAction } from "../helperFunctions/useAction.js";
import { IoLogOutOutline } from "react-icons/io5";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const Navbar = () => {
  const navigate = useNavigate();
  const handleGetAction = useAction(getMySelf);
  const handleSignOut = useAction(signOut);
  useEffect(() => {
    handleGetAction();
  }, [handleGetAction]);
  const user = useSelector((globalState) => globalState.user);
  const handleLogout = () => {
    handleSignOut();
    navigate("/login");
  };

  const accept = () => {
    handleLogout();
  };

  const confirmLogOut = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept,
    });
  };

  return (
    <>
      <ConfirmDialog />
      <nav className="navbar navbar-expand-lg sticky-top shadow-sm nav_background">
        <div className="container-fluid mx-3 py-1">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className=" d-lg-flex justify-content-between w-100">
              {/** <div className="navbar-nav my-auto">
                <Link
                  className="nav-link fs-5"
                  aria-current="page"
                  to="/dashboard"
                >
                  Home
                </Link>
              </div> */}
              <div className="d-flex justify-content-center  py-2 align-items-center">
                <Link to="/dashboard">
                  <img
                    src="/images/bimabooklogoBlue.png"
                    alt="logo"
                    width="180px"
                  />
                </Link>
              </div>

              <div className="d-lg-flex justify-content-between">
                <UserProfile user={user} />
                <div
                  className="profile_head bg-white shadow-lg rounded-pill px-3 mx-2"
                  onClick={confirmLogOut}
                >
                  <IoLogOutOutline className="fs-3" />
                  <span className="logout">Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
