import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import CollectionForm from "./CollectionForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CardComponent from "./CardComponent";
import LedgerTable from "./LedgerTable";
import { IoGridOutline } from "react-icons/io5";
import { CiCircleList } from "react-icons/ci";
import Select from "react-select";
import { useAction } from "../helperFunctions/useAction";
import { getYearlyLedger } from "../redux/reducers/ledger/ledger.action";
import { useSelector } from "react-redux";

const LedgerData = ({ basedOn, handleFormSubmit, masterData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState("all");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [view, setView] = useState("list");
  const [filteredValue, setFilteredValue] = useState("All");
  const [policies, setPolicies] = useState([]);
  const [ledgerType, setLedgerType] = useState("");

  const handleGetYearlyLedger = useAction(getYearlyLedger);

  const yearlyLedgerList = useSelector(
    (state) => state.ledgerReducer.yearlyLedgers
  );

  useEffect(() => {
    setPolicies(yearlyLedgerList);
  });

  const formatDate = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleDateRangeChange = (range) => {
    setSelectedDateRange(range);
    // Reset custom date range if switching from "custom"
    if (range !== "custom") {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };

  useEffect(() => {
    if (basedOn === "agentName") {
      setLedgerType("agentId");
    } else if (basedOn === "customerName") {
      setLedgerType("customerId");
    } else if (basedOn === "serviceProviderName") {
      setLedgerType("serviceProviderId");
    } else if (basedOn === "insuranceCompany") {
      setLedgerType("insuranceCompanyId");
    }
  }, [basedOn, ledgerType]);

  const fetchData = async () => {
    if (
      !filteredValue ||
      !selectedDateRange ||
      !startDate ||
      !endDate ||
      !ledgerType ||
      !basedOn
    ) {
      // If any required state value is not set, return early or handle appropriately
      return;
    }

    let params = {
      [ledgerType]: filteredValue === "All" ? basedOn : filteredValue,
    };

    switch (selectedDateRange) {
      case "all":
        params.openingBalanceDate = formatDate(
          new Date(new Date().getFullYear() - 1, 2, 31)
        );
        break;
      case "thisMonth":
        params.startDate = formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        );
        params.endDate = formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        );
        params.openingBalanceDate = formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        );
        break;
      case "lastMonth":
        params.startDate = formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        );
        params.endDate = formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        );
        params.openingBalanceDate = formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0)
        );
        break;
      case "currentYear":
        params.startDate = formatDate(new Date(new Date().getFullYear(), 3, 1));
        params.endDate = formatDate(
          new Date(new Date().getFullYear() + 1, 2, 31)
        );
        params.openingBalanceDate = formatDate(
          new Date(new Date().getFullYear(), 2, 31)
        );
        break;
      case "custom":
        params.openingBalanceDate = formatDate(new Date(startDate) - 1);
        params.startDate = formatDate(startDate);
        params.endDate = formatDate(endDate);
        break;
      default:
        break;
    }

    handleGetYearlyLedger(params);
  };

  useEffect(() => {
    fetchData();
  }, [
    filteredValue,
    selectedDateRange,
    startDate,
    endDate,
    basedOn,
    ledgerType,
    handleGetYearlyLedger,
  ]);

  const handleCollection = (value, id) => {
    setSelectedCompany(value);
    setSelectedId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCompany(null);
    setSelectedId(null);
  };

  const handleViewChange = () => {
    setView(view === "grid" ? "list" : "grid");
  };
  const findSelectedOption = (options, value) => {
    return options.find((option) => option.value === value) || null;
  };
  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="row mb-4 col-md-7">
          <div className="col-md-4">
            <label>Date Range:</label>
            <select
              className="form-select"
              value={selectedDateRange}
              onChange={(e) => handleDateRangeChange(e.target.value)}
            >
              <option value="all">All</option>
              <option value="thisMonth">This Month</option>
              <option value="lastMonth">Last Month</option>
              <option value="currentYear">Current Year (Fiscal Year)</option>
              <option value="custom">Custom</option>
            </select>
          </div>
          {selectedDateRange === "custom" && (
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 d-flex flex-column ">
                  <label className="mb-1">Start Date:</label>
                  <DatePicker
                    selected={startDate}
                    showIcon
                    onChange={(date) => setStartDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label className="mb-1">End Date:</label>
                  <DatePicker
                    selected={endDate}
                    showIcon
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-4">
          <label>Filter By Name:</label>
          {masterData && (
            <Select
              className="w-100"
              value={findSelectedOption(
                [
                  { value: "All", label: "All" },
                  ...masterData.map((master) => ({
                    value: master._id,
                    label: master[basedOn],
                  })),
                ],
                filteredValue
              )}
              defaultValue={{ label: "All", value: "All" }}
              onChange={(selectedOption) =>
                setFilteredValue(selectedOption ? selectedOption.value : "")
              }
              options={[
                { value: "All", label: "All" },
                ...masterData.map((master) => ({
                  value: master._id,
                  label: master[basedOn],
                })),
              ]}
              isClearable
              placeholder="Choose..."
              required
            />
          )}
        </div>

        <div className="col-md-1 d-flex justify-content-end align-items-center">
          {view === "grid" ? (
            <CiCircleList
              onClick={handleViewChange}
              className="fs-2 border border-primary rounded p-1"
              role="button"
              title="Show Line"
            />
          ) : (
            <IoGridOutline
              onClick={handleViewChange}
              className="fs-2 border border-primary rounded p-1"
              role="button"
              title="Show Grid"
            />
          )}
        </div>
      </div>
      {view === "grid" ? (
        <CardComponent
          handleCollection={handleCollection}
          ledgerArray={policies}
          basedOn={basedOn}
        />
      ) : (
        <LedgerTable
          handleCollection={handleCollection}
          ledgerArray={policies}
          basedOn={basedOn}
        />
      )}
      <Modal show={showModal} title="Update Payment" onClose={handleCloseModal}>
        <CollectionForm
          company={selectedCompany}
          keyId={selectedId}
          onClose={handleCloseModal}
          onFormSubmit={handleFormSubmit} // Pass the submit handler
          basedOnName={basedOn}
          basedOnId={ledgerType}
        />
      </Modal>
    </div>
  );
};

export default LedgerData;
